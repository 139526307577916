import React from 'react';
import icon from './../../assets/icons/add_circle_outline-white-24px.svg';
import { useTranslation } from 'react-i18next';
import Points from '../Points';
import bgImg from './../../assets/SecureServices/Bg.jpg';
import img from './../../assets/Technology/techno-1.jpg';
import img2 from './../../assets/eng/remote.jpg';

const RemoteModal = (props) => {
    const { t } = useTranslation();
    const engPoints1 = ['Control1Point1', 'Control1Point2', 'Control1Point3', 'Control1Point4', 'Control1Point5', 'Control1Point6', 'Control1Point7', 'Control1Point8', 'Control1Point9', 'Control1Point10'];

    return (
        <div id={props.id} className='modal p-6 md:p-12 expandable' style={{ backgroundImage: `url('${bgImg}')`, backgroundSize: 'cover' }}>
            <p className='text modalInfo mb-4 md:mb-12'>{t("FarmModalInfo")}</p>
            <div className='rowModal flex md:space-x-4'>
                <img src={img2} className='mb-8 xl:w-1/2 object-contain self-center' alt='' />
                <div className='lg:pr-4'>
                    <p className='info modalTitle self-start w-full'>{t('ControlTitle1')}</p>
                    <p className='info self-start w-full'>{t('ControlInfo1')}</p>
                </div>
            </div>
            <div className='reverseModal flex justify-between py-6'>
                <div className='ml-4'>
                    <p className='text modalTitle mt-6 mb-4'>{t('Control1PointsTitle')}</p>
                    {engPoints1.map(point =>
                        <Points key={point} point={point} white />
                    )}
                </div>
                <img src={img} className='xl:w-1/2 object-cover' alt='' />
            </div>
            <div className='m-auto'>
                <img onClick={() => props.clickClose(props.setModal, false, props.id)} className='modalClose' src={icon} alt='' />
            </div>
        </div>

    )
}

export default RemoteModal;