import React, { useEffect, useState } from 'react';
import Footer from './Components/Footer';
import HomePage from './Components/HomePage/HomePage';
import About from './Components/About/About';
import SecureServices from './Components/SecureServises/SecureServises';
import MassEvents from './Components/MassEvents/MassEvents';
import Technology from './Components/Technology/Technology';
import ContactUs from './Components/ContactUs/ContactUs';
import Jobs from './Components/Jobs/Jobs';
import Customers from './Components/Customers/Customers';
import Specializations from './Components/Specializations/Specializations';
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import i18n from './i18n';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from "@material-ui/core";
import Command from './Components/Command/Command';
import Accessibility from './Components/Accessibility/Accessibility';
import { appColor } from './assets/appColor';
//import ReactGA from 'react-ga';
import ReactGA from "react-ga4";


function App(props) {
  const [render, setRender] = useState(false);

  useEffect(() => {
    let dir = 'rtl';
    let lang = localStorage.getItem('lang');
    if (localStorage.length > 0) dir = localStorage.getItem('dir');
    document.body.style.direction = dir;
    i18n.changeLanguage(lang);
    setRender(true);
  }, []);


  // ReactGA.initialize('UA-190328701-1');
  ReactGA.initialize('G-L3FCVNK8XQ');
  useEffect(() => {
    ReactGA.set({ page: props.location.pathname });
    //ReactGA.pageview(props.location.pathname);
    ReactGA.send({ hitType: "pageview", page: props.location.pathname});
  }, [props.location.pathname]);



  const theme = createMuiTheme({

    direction: 'rtl',
    overrides: {
      MuiCollapse: {

      }

    },
    palette: {
      primary: {
        main: '#CBA65C'

      },
      secondary: {
        main: '#fff'
      }
    },


  });


  let routes = (
    <Switch>
      <Route path="/בית" exact render={props => <HomePage {...props} />} />
      <Route path="/אודות" render={props => <About {...props} />} />
      <Route path="/שירותי-אבטחה" render={props => <SecureServices {...props} />} />
      <Route path="/אבטחת-אירועים-המוניים" render={props => <MassEvents {...props} />} />
      <Route path="/התמחויות-נוספות" render={props => <Specializations {...props} />} />
      <Route path="/מוקד-תצפית-וטכנולוגיה" render={props => <Technology {...props} />} />

      <Route path="/צור-קשר" render={props => <ContactUs {...props} />} />
      <Route path="/דרושים" render={props => <Jobs {...props} />} />
      <Route path="/בין-לקוחותינו" render={props => <Customers {...props} />} />

      <Route path="/Home" exact render={props => <HomePage {...props} />} />
      <Route path="/About" exact render={props => <About {...props} />} />
      <Route path="/Security-Services" render={props => <SecureServices {...props} />} />
      <Route path="/Public-Events" render={props => <MassEvents {...props} />} />
      <Route path="/Command-And-Control" render={props => <Command {...props} />} />

      <Route path="/Contact-Us" render={props => <ContactUs {...props} />} />
      <Route path="/Jobs" render={props => <Jobs {...props} />} />
      <Route path="/Customers" render={props => <Customers {...props} />} />

      <Redirect to={{ pathname: i18n.language === 'he' ? "/בית" : "/Home" }} />

    </Switch>
  );


  const pageId = props.location.search.split("PageId=")[1];

  if (pageId === "16941") window.location.href = '/About'
  else if (pageId === "17104") window.location.href = '/Security-Services#3'
  else if (pageId === "17105") window.location.href = '/Public-Events'
  else if (pageId === "17108") window.location.href = '/Security-Services#5'
  else if (pageId === "17219") window.location.href = '/Security-Services#5'
  else if (pageId === "34621") window.location.href = '/התמחויות-נוספות#2'
  else if (pageId === "16932") window.location.href = '/התמחויות-נוספות'
  else if (pageId === "16931") window.location.href = '/Contact-Us'
  else if (pageId === "49396") window.location.href = '/מוקד-תצפית-וטכנולוגיה'

  return (
    render &&
    <ThemeProvider theme={theme}>
      <div>
        {routes}
        <Accessibility borderColor={'#e2e8f0'} btnBgColor={appColor} />
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default withRouter(App);
