import React from 'react';
import { useTranslation } from 'react-i18next';
import arrow from './../../assets/icons/whiteArrow.svg';
import { withRouter } from 'react-router-dom';
import i18n from './../../i18n';

const Card = (props) => {
    const { t } = useTranslation();
    return <div className='homeCard m-auto md:float-left' style={{ backgroundColor: 'rgba(35, 149, 172, 0.75)' }}>
        <div className='p-6 md:p-12 flex flex-col'>
            <p className='text text-3xl lg:text-4xl xl:text-5xl bold leading-none'>{t("AboutPageShortInfo")}</p>
            <div className='flex flex-row cursor onhoverWithMargin' onClick={() => props.history.push(t('MenuAboutPage'))}>
                <p className='text md:text-2xl leading-none'>{t("About")}</p>
                <img className={`w-4 self-center lg:self-center md:pt-0 mx-2 ${i18n.language==='en'?' imgFlip':''}`} src={arrow} alt='' />
            </div>
        </div>
    </div>
}


export default withRouter(Card);