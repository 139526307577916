import React from 'react';
import yellowArrow from './../assets/yellowArrow.png';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import icon from './../assets/icons/add_circle_outline-24px(1).svg';
import blueIcon from './../assets/icons/add_circle_outline-blue-24px.svg';
import { HashLink as Link } from 'react-router-hash-link';
import i18n from './../i18n';


const ImgText = (props) => {
    const style = {
        backgroundPosition: 'center',
        backgroundColor: props.filter ? 'rgba(0, 0, 0,0.50)' : `${props.opacity ? 'rgba(0, 0, 0, 0.65)' : props.bg}`,
        backgroundBlendMode: 'multiply',
    };
    const clickHandler = () => {
        window.scrollTo(0, 0);
        props.history.push(`/${props.linkTo}`)
    }
    const clickMoreInfoHandler = () => {
        props.clickClose(props.setModal, true, props.id)
    }
    const { t } = useTranslation();
    const arrow = <img className={`w-4 self-center mx-2 ${i18n.language === 'en' ? ' imgFlip' : ''}`} src={yellowArrow} alt='' />

    const linkText = i18n.language === 'he' ?
        <div className='onhover'>
            <p className='flex flex-row lg:text-2xl'>
                {t(props.linkTxt??'HomePageLinkText')}
        {arrow}
            </p>
        </div> : <div className='onhover'>
            <p className='flex flex-row lg:text-2xl'>
                {t(props.linkTxt??'HomePageLinkText')}
                {arrow}
            </p>
        </div>;

    const moreInfoDiv = (<div id={props.id} onClick={clickMoreInfoHandler} className='flex flex-row py-2 cursor w-32 md:w-64 z-40 relative '>
        <p className=' md:text-lg lg:text-2xl ' style={props.infoClr ? { color: props.infoClr } : { color: "#F6FF00" }}>{t('moreInfo')}</p>
        <img className={`h-5 w-5 lg:w-6 lg:h-6 my-auto mx-2 ${props.open ? ' transform rotate-45' : null}`} src={props.infoClr ? blueIcon : icon} alt='' />
    </div>)

    return (
        <div style={style} className={`flex justify-center mx-auto items-center flex-col w-1/2 md:w-2/5 h-full ${props.left ? ' float-left' : ''} ${props.right ? ' float-right' : ''} ${props.styling ? props.styling : ''}`}>
            <div className=''>
                <p className={`text mx-auto text-4xl md:text-5xl xl:text-6xl bold leading-none uppercase${props.text ? ' text-black' : ''}`} style={{ color: props.titleClr }}>{t(props.title)}</p>
                {props.moreInfo ?
                    !props.open ? <Link to={`#${props.id}`} scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
                        {moreInfoDiv}
                    </Link> :
                        moreInfoDiv
                    : null}
                {props.linkTxt ?
                    <div onClick={clickHandler} className={`flex flex-row md:text-lg justify-start cursor `} style={{ display: 'inline-block', color: '#F6FF00', zIndex: '999' }}>
                        {props.contact ? <div className='flex flex-row onhover lg:text-2xl'>{t(props.linkTxt)}{arrow}</div> : linkText}</div>
                    : null}
            </div >
        </div>

    )
}


export default withRouter(ImgText);