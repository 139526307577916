import React from 'react';
import logo from './../../assets/Logo.png';
import { useTranslation } from 'react-i18next';


const Info = (props) => {
    const { t } = useTranslation();
    return (
        <div>
            <img className='mx-auto my-6 md:mt-12 w-32' src={logo} alt='' />
            <p className='m-auto text-center w-3/4 md:w-2/3 font-bold text-xl md:text-3xl' style={{ color: '#2395AC' }}>{t("HomePageInfo")}</p>
        </div>
    )
}

export default Info;