import React from 'react';
import Header from '../Header/Header';
import Contact from '../ContactFooter';
import { appColor } from './../../assets/appColor';
import { useTranslation } from 'react-i18next';
import ShortInfo from './../ShortInfo';
import headerImg from './../../assets/Customers/Header.jpg';
import Points from './../Points';
import logo1 from './../../assets/Customers/new/13.png';
import logo4 from './../../assets/Customers/new/hetzlia.png';
import logo5 from './../../assets/Customers/new/israel_football.png';
import logo6 from './../../assets/Customers/new/maccabiah.png';
import logo7 from './../../assets/Customers/new/namal.png';
import logo8 from './../../assets/Customers/new/shuki.png';

const Customers = (props) => {
    const { t } = useTranslation();
    const logos = [logo8, logo1, logo4, logo5, logo6, logo7];
    const points = ["CustomersPagePoint1", "CustomersPagePoint2", "CustomersPagePoint3", "CustomersPagePoint4", "CustomersPagePoint5", "CustomersPagePoint6", "CustomersPagePoint7", "CustomersPagePoint8", "CustomersPagePoint9", "CustomersPagePoint10", "CustomersPagePoint11"];
    return (
        <div>
            <Header imgUrl={headerImg} pageTitle={t('ourCustomers')} position={'top'} filter={'brightness(50%)'} />
            <ShortInfo text={t("CustomersPageShortInfo")} />
            <div className='grid grid-cols-4 m-auto customers'>
                {logos.map(logo =>
                    <div key={Math.random()} className='customerLogo shadow-lg flex items-center m-auto'>
                        <img className='object-contain m-auto p-2' key={Math.random()} src={logo} alt='' />
                    </div>
                )}
            </div>

            <div className='pointsDiv customers'>
                <p className='text modalInfo' style={{ color: appColor }}>{t('CustomersPageInfo')}</p>
                {points.map(point =>
                    <Points key={Math.random()} point={point} />
                )}
            </div>
            <Contact bg />
        </div>
    )
}

export default Customers;