import React from 'react';
import i18n from './../i18n';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ChangeLang = (props) => {
    const { t } = useTranslation();
    const clickHandler = (lang) => {
        if (props.location.pathname === `/${t('MenuTechnologyPage')}` || props.location.pathname === `/${t('MenuSpecializationsPage')}` || props.location.pathname === `/${t('MenuCommandPage')}`) {
            window.scrollTo(0, 0);
            props.history.push(t('MenuHomePage'));
        }
        let dir = 'ltr';
        if (lang === 'he') dir = 'rtl'
        localStorage.setItem('lang', lang);
        localStorage.setItem('dir', dir);
        i18n.changeLanguage(lang);
        document.body.style.direction = dir;
    }

    return (
        <div className='justify-center items-center flex flex-row text-center'>
            <p onClick={() => clickHandler('en')} className={`text-center onhoverMenu ${props.footer ? ' text-black text-xs md:text-base bold' : ' text-white md:text-xl lg:text-2xl xl:text-4xl font-bold'}`}>ENGLISH  </p>
            <span className={`text-center mx-1 ${props.footer ? ' text-black text-sm md:text-sm lg:text-lg' : ' text-white text-xl md:text-3xl lg:text-4xl bold'}`}>/</span>
            <p onClick={() => clickHandler('he')} className={`text-center mx-1 onhoverMenu ${props.footer ? ' text-black text-sm md:text-sm font-bold lg:text-lg' : ' text-white menuTitle bold'}`}> עברית  </p>
        </div>
    )
}

export default withRouter(ChangeLang);