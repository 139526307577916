import React, { useState} from 'react';
import Header from './../Header/Header';
import { useTranslation } from 'react-i18next';
import headerImg from './../../assets/SecureServices/Header.jpg';
import ShortInfo from './../ShortInfo';
import img from './../../assets/SecureServices/1.jpg';
import StationaryServices from './StationaryServices';
import { appColor } from './../../assets/appColor';
import BizModal from './BizModal';
import Contact from '../ContactFooter';
import GroupsModal from './GroupsModal';
import FarmModal from './FarmModal';
import ImgText from './../ImgText';
import Collapse from '@material-ui/core/Collapse';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import seaportImg from './../../assets/eng/seaportsmain.jpg';
import airportmain from './../../assets/eng/airportsmain.jpg';
import i18n from './../../i18n';
import englastimg from './../../assets/Specializations/special-03.jpg';
import frameImgHe from './../../assets/SecureServices/frameImgHe.jpg';
import img2 from './../../assets/SecureServices/groups-02.jpg';
import parks from './../../assets/SecureServices/parks.jpg';


const SecureServises = (props) => {
    const { t } = useTranslation();
    const [farmModalOpen, setFarmModalOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [bizModalOpen, setBizModalOpen] = useState(false);
    const [groupModalOpen, setGroupModalOpen] = useState(false);

    const clickModalHandler = (setModalOpen, open, id) => {
        setModalOpen((prev) => !prev);
        if (!open) {
            const el = document.getElementById(id);
            el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    const style = {
        padding: 20,
        backgroundColor: 'rgba(0,0,0,0.65)',
        backgroundBlendMode: 'multiply'
    };

    return (
        <div>
            <Header imgUrl={headerImg} pageTitle={t('Services')} />
            <ShortInfo text={t("SecurityPageShortInfo")} />
            <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} delay={0} offset={10} animateOnce={true} >
                <div className='background flex' style={{ backgroundImage: `url('${i18n.language === 'he' ? img : seaportImg}')`, backgroundSize: 'cover', backgroundPositionY: i18n.language === 'en' ? 'bottom' : '60%' }}>
                    <div className='m-auto w-3/4 md:w-1/2 lg:w-2/5 xl:w-1/3 flex items-center' style={style}>
                        <div className='flex items-center px-6'>
                            <ImgText title={t('FarmModalText')} moreInfo setModal={setFarmModalOpen} open={farmModalOpen} clickClose={clickModalHandler} id={`${t("FarmModalText").replace(/\s+/g, '-').toLowerCase()}`} styling={' w-3/4 md:w-4/5 lg:w-full lg:px-6 py-4 lg:py-8'} />
                        </div>
                    </div>
                </div>
            </ScrollAnimation>
            <Collapse in={farmModalOpen} timeout={990}><FarmModal setModal={setFarmModalOpen} clickClose={clickModalHandler} id={`${t("FarmModalText").replace(/\s+/g, '-').toLowerCase()}`} /> </Collapse>
            <div className='md:hidden'>
                <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} delay={0} offset={10} animateOnce={true} >
                    <div className='background flex' style={{ backgroundImage: i18n.language === 'he' ? `url('${frameImgHe}')` : `url('${img2}')` }}>
                        <div className='m-auto w-2/5' style={style}>
                            <ImgText title={"SecureServicesInfo"} moreInfo open={open} setModal={setOpen} clickClose={clickModalHandler} id={2} styling={' w-3/4'} />
                        </div>
                    </div>
                </ScrollAnimation>
                <Collapse in={open} timeout={960}> <StationaryServices setModal={setOpen} clickClose={clickModalHandler} id={2} /></Collapse>
            </div>

            <div className='hidden md:inline'>
                <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} delay={0} offset={10} animateOnce={true} >
                    <div className='colReverse'>
                        <img src={i18n.language === 'he' ? frameImgHe : img2} className='background w-3/5' alt='' />
                        <div className='background w-2/5 flex px-8' style={{ backgroundColor: appColor }}>
                            <ImgText title={"SecureServicesInfo"} left styling={' xl:mx-40 md:mx-12'} moreInfo open={open} setModal={setOpen} id={`${t("SecureServicesInfo").replace(/\s+/g, '-').toLowerCase()}`} clickClose={clickModalHandler} />
                        </div>
                    </div>
                </ScrollAnimation>
                <Collapse in={open} timeout={1000}> <StationaryServices setModal={setOpen} clickClose={clickModalHandler} id={`${t("SecureServicesInfo").replace(/\s+/g, '-').toLowerCase()}`} /></Collapse>
            </div>

            <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} delay={0} offset={10} animateOnce={true} >
                <div className={`${i18n.language === 'he' ? 'md:flex flex-row-reverse' : ' md:flex flex-row-reverse'}`} style={{ backgroundColor: '#F8F8F8' }}>
                    <div className={`background w-1/2 flex justify-start hidden md:inline`}>
                        <ImgText title={"SecureServicesPageBizTitle"} infoClr={appColor} moreInfo open={bizModalOpen} setModal={setBizModalOpen} id={`${t("SecureServicesPageBizTitle").replace(/\s+/g, '-').toLowerCase()}`} clickClose={clickModalHandler} text={'black'} />
                    </div>
                    <React.Fragment>
                        <div className='hidden md:inline w-1/2'>
                            <div className='w-full h-full'>
                                <img className='object-cover w-full h-full' src={i18n.language === 'he' ? parks : airportmain} alt='' />
                            </div>
                        </div>
                        <div className='md:hidden'>
                            <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} delay={0} offset={10} animateOnce={true} >
                                <div className='background w-full flex object-cover' style={{ backgroundImage: `url('${i18n.language === 'he' ? parks : airportmain}')` }}>
                                    <div className='m-auto' style={style}>
                                        <ImgText title={"SecureServicesPageBizTitle"} moreInfo open={bizModalOpen} setModal={setBizModalOpen} clickClose={clickModalHandler} id={`${t("SecureServicesPageBizTitle").replace(/\s+/g, '-').toLowerCase()}`} styling={' w-3/4'} />
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </React.Fragment>
                </div>
            </ScrollAnimation>
            <Collapse in={bizModalOpen} timeout={1000}><BizModal clr={appColor} setModal={setBizModalOpen} clickClose={clickModalHandler} id={`${t("SecureServicesPageBizTitle").replace(/\s+/g, '-').toLowerCase()}`} /></Collapse>

            <div className='hidden md:inline'>
                <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} delay={0} offset={10} animateOnce={true} >
                    <div className={`flex flex-row`}>
                        <div className={`bg-white flex ${i18n.language === 'he' ? ' w-1/2' : ' w-1/2'}`}>
                            <ImgText title={"SecurityServiceGroup"} infoClr={appColor} moreInfo open={groupModalOpen} setModal={setGroupModalOpen} id={`${t("SecurityServiceGroup").replace(/\s+/g, '-').toLowerCase()}`} clickClose={clickModalHandler} text={'black'} right />
                        </div>
                        <img className='background w-1/2 m-auto' src={i18n.language === 'he' ? img2 : englastimg} alt='' />
                    </div>
                </ScrollAnimation>
                <Collapse in={groupModalOpen} timeout={1000}><GroupsModal clr={appColor} setModal={setGroupModalOpen} clickClose={clickModalHandler} id={`${t("SecurityServiceGroup").replace(/\s+/g, '-').toLowerCase()}`} /></Collapse>
            </div>
            <div className='md:hidden'>
                <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} delay={0} offset={10} animateOnce={true}>
                    <div className='background w-full flex object-cover' style={{ backgroundImage: `url('${i18n.language === 'he' ? img2 : englastimg}')` }}>
                        <div className='m-auto' style={style}>
                            <ImgText title={"SecurityServiceGroup"} moreInfo open={groupModalOpen} setModal={setGroupModalOpen} clickClose={clickModalHandler} id={6} styling={' w-3/4'} />
                        </div>
                    </div>
                </ScrollAnimation>
                <Collapse in={groupModalOpen} timeout={1000}><GroupsModal clr={appColor} setModal={setGroupModalOpen} clickClose={clickModalHandler} id={6} /></Collapse>
            </div>
            <Contact bg />
        </div>
    )
}

export default SecureServises;