import React, { useState} from 'react';
import Header from '../Header/Header';
import headerImg from './../../assets/Specializations/Header.jpg';
import img2 from './../../assets/Specializations/special-02.jpg';
import img3 from './../../assets/Specializations/special-03.jpg';
import Contact from '../ContactFooter';
import { useTranslation } from 'react-i18next';
import ShortInfo from '../ShortInfo';
import InvestigationServices from './InvestigationServices';
import Training from './Training';
import electroLine from './../../assets/Elements/ElectroLines.png';
import ImgText from './../ImgText';
import Collapse from '@material-ui/core/Collapse';
import ScrollAnimation from 'react-animate-on-scroll';
import { Redirect } from 'react-router-dom';
import i18n from './../../i18n';
import { appColor } from './../../assets/appColor';

const Specializations = (props) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [openTraining, setOpenTraining] = useState(false);
    const clickModalHandler = (setModalOpen, open, id) => {
        setModalOpen((prev) => !prev);
        if (!open) {
            const el = document.getElementById(id);
            el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    return (
        i18n.language === 'he' ?
            <div>
                <Header imgUrl={headerImg} pageTitle={t('Specializations')} filter />
                <ShortInfo text={t("SpecializationsPageShortInfo")} />
                <div className='hidden md:inline'>
                    <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} offset={10} delay={0} animateOnce={true} >
                        <div className='rowStyle relative'>
                            <img src={img2} className='background md:w-7/12' alt='' />
                            <div className='w-1/2' style={{ backgroundColor: '#00527F' }}>
                                <ImgText title={"InvestigationServices"} moreInfo open={open} setModal={setOpen} clickClose={clickModalHandler} id={t("InvestigationServices").replace(/\s+/g, '-').toLowerCase()} />
                            </div>
                            <img src={electroLine} alt='' className='electroLine hidden md:inline absolute mt-40 lg:mt-64 xl:mt-48 w-full' />
                        </div>
                    </ScrollAnimation>
                    <Collapse in={open} timeout={1000}><InvestigationServices setModal={setOpen} clickClose={clickModalHandler} id={t("InvestigationServices").replace(/\s+/g, '-').toLowerCase()} /> </Collapse>

                    <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} offset={10} delay={0} animateOnce={true} >
                        <div className='rowStyle'>
                            {/* <div className='background w-1/2' style={{ backgroundImage: `url('${img}')` }}> */}
                            <div className='background w-1/2' style={{ backgroundColor:appColor }}>
                                <ImgText title={"trainingDivision"} moreInfo open={openTraining} setModal={setOpenTraining} clickClose={clickModalHandler} id={t("trainingDivision").replace(/\s+/g, '-').toLowerCase()} styling={' md:w-3/5 xl:w-1/2'} />
                            </div>
                            <img src={img3} className='background md:w-1/2' alt='' />
                        </div>
                    </ScrollAnimation>
                    <Collapse in={openTraining} timeout={1000}><Training setModal={setOpenTraining} clickClose={clickModalHandler} id={`${t("trainingDivision").replace(/\s+/g, '-').toLowerCase()}`} /> </Collapse>
                </div>
                <div className='rowStyle md:hidden'>
                    <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} delay={0} offset={10} animateOnce={true} >
                        <div className='background w-full' style={{ backgroundImage: `url('${img2}')` }}>
                            <ImgText title={"InvestigationServices"} moreInfo open={open} setModal={setOpen} clickClose={clickModalHandler} id={3} fullWidth right styling={' mx-8'} />
                        </div>
                    </ScrollAnimation>
                    <Collapse in={open} timeout={1000}><InvestigationServices setModal={setOpen} clickClose={clickModalHandler} id={3} /> </Collapse>

                    <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} delay={0} offset={10} animateOnce={true} >
                        <div className='background w-full' style={{ backgroundImage: `url('${img3}')` }}>
                            <ImgText title={"trainingDivision"} titleClr={'#1E345B'} moreInfo open={openTraining} setModal={setOpenTraining} clickClose={clickModalHandler} id={4} left styling={' mx-4 w-1/2'} />
                        </div>
                    </ScrollAnimation>
                    <Collapse in={openTraining} timeout={1000}><Training setModal={setOpenTraining} clickClose={clickModalHandler} id={4} /> </Collapse>
                </div>
                <Contact bg />
            </div>
            : <Redirect to="/" />
    )
}

export default Specializations;