import React from 'react';
import { useTranslation } from 'react-i18next';

const Points = (props) => {
    const { t } = useTranslation();
    return (
        <div key={Math.random()} className='flex flex-col'>
            <div  className='my-2 flex flex-row'>
                <div className={`rounded-full self-start w-2 h-2 mt-1 md:mt-2 px-1 mx-1 ${props.white ? ' bg-white' : ' bg-black'}`}></div>
                <span className={`my-auto text-lg lg:text-2xl lg:w-11/12 leading-none mx-2 ${props.white ? ' text-white' : ' text-black'}`}>{t(props.point)}</span>

            </div>
            {props.subPoints ?
                props.subPoints.map(subPoint =>
                    <div key={Math.random()} className='my-1 flex flex-row mx-10'>
                        <div className={`self-start px-1 mx-1 ${props.white ? ' text-white' : ' text-black'}`}>-</div>
                        <span className={`my-auto text-lg lg:text-2xl lg:w-11/12 leading-none font-light  ${props.white ? ' text-white' : ' text-black'}`}> {t(subPoint)}</span>
                    </div>
                ) : null
            }
        </div>
    )
}

export default Points;

