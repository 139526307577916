import React from 'react';
import img from './../../assets/Specializations/special-04.jpg';
import icon from './../../assets/icons/add_circle_outline-24px(1).svg';
import Points from './../Points';
import { useTranslation } from 'react-i18next';

const InvestigationServices = (props) => {
    const { t } = useTranslation();
    const points = ["SpecializationsInfoPoint1", "SpecializationsInfoPoint2", "SpecializationsInfoPoint3", "SpecializationsInfoPoint4", "SpecializationsInfoPoint5", "SpecializationsInfoPoint6", "SpecializationsInfoPoint7",]
    return (
        <div className='modal p-6 md:p-12' style={{ backgroundColor: "#00527F" }}>
            <div className='reverseModal m-auto'>
                <div className='flex flex-col mb-6 w-full xl:mr-4'>
                    <p className='modalTxt mt-4 xl:mt-0'>{t("InvestigationSeviceInfo")}</p>
                        <div className='my-4'>
                            {points.map(point =>
                                <Points key={point} point={point} white />
                            )}
                        </div>
                </div>
                <img className='xl:w-1/2 object-cover' src={img} alt='' />
            </div>

            <div className='m-auto mt-6 md:mt-0'>
                <img onClick={() => props.clickClose(props.setModal, false, props.id)} className='modalClose' src={icon} alt='' />
            </div>
        </div>
    )
}

export default InvestigationServices;