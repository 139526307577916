import React from 'react';
import Header from '../Header/Header';
import headerImg from './../../assets/About/Header.jpg';
import img from './../../assets/About/About-1.jpg';
import img2 from './../../assets/About/About-2.jpg';
import Contact from '../ContactFooter';
import { appColor } from './../../assets/appColor';
import { useTranslation } from 'react-i18next';
import ShortInfo from './../ShortInfo';
import Points from './../Points';
import ScrollAnimation from 'react-animate-on-scroll';
import i18n from './../../i18n';

const About = (props) => {
    const { t } = useTranslation();
    const points = ["AboutPagePoint1", "AboutPagePoint2", "AboutPagePoint3", "AboutPagePoint4", "AboutPagePoint5", "AboutPagePoint6", "AboutPagePoint7"];
    i18n.language === 'en' && points.push("AboutPagePoint8", "AboutPagePoint9");

    return (
        <div>
            <Header imgUrl={headerImg} pageTitle={t('About')} />
            <ShortInfo text={t("AboutPageShortInfo")} width={'w-4/5 md:w-1/3'} />
            <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} delay={0} animateOnce={true}>
                <div className='rowStyle'>
                    <img src={img} className='background w-full md:w-1/2 object-top' alt='' />
                    {i18n.language === 'he' ?
                        <div className='w-full md:w-1/2 background flex' style={{ backgroundColor: appColor }}>
                            <div className='w-5/6 lg:w-2/3 m-auto'>
                                <p className='info m-auto lg:ml-20 xl:text-3xl'>שפע ביטחון בע"מ נוסדה </p>
                                <p className='info m-auto lg:ml-20 xl:text-3xl'>ב- 1982 על ידי יהונתן שפע, בכיר מבצעים לשעבר במוסד ויוצא שייטת 13. יהונתן הציג לראשונה נהלים, תורות לחימה ודוקטרינות שטרם הכירו באזרחות</p>
                            </div>  </div> :
                        <div className='w-full md:w-1/2 py-2 flex' style={{ backgroundColor: appColor }}>
                            <p className='info m-auto lg:ml-20 w-5/6 xl:w-2/3 py-6'> {t('AboutPageInfo')}</p>
                        </div>
                    }
                </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} delay={0} animateOnce={true}>
                <div className='colReverse'>
                    <div className={`pointsDiv px-4 lg:px-10 w-full md:w-1/2 flex flex-col ${i18n.language === 'en' ? ' self-center' : ''} `}>
                        <p className='text modalTitle md:w-4/5' style={{ color: appColor }}>{t('AboutPagePointsTitle')}</p>
                        {points.map(point =>
                            <Points key={point} point={point} />
                        )}
                    </div>
                    <img src={img2} className='md:w-1/2' alt='' />
                </div>
            </ScrollAnimation>
            <Contact bg />
        </div>
    )
}

export default About;