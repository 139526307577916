import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import { appColor } from '../../assets/appColor';
import { useTranslation } from 'react-i18next';
import headerImg from './../../assets/Jobs/header.jpg';
import { useForm } from "react-hook-form";
import { TextField, Button } from "@material-ui/core";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import i18n from '../../i18n';
import axios from '../../axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Redirect } from 'react-router-dom';

const Jobs = (props) => {
    const { register, formState, handleSubmit, errors } = useForm({ mode: "onBlur" });
    const [buttonAble, setButtonAble] = useState(formState.isValid);
    const [submitClicked, setSubmitClicked] = useState(false);
    const captchaKey = '6Lep9E4aAAAAAIsBYRzQtElYY_KUyYvycct-dkED';
    const [recaptcha, setRecaptcha] = useState(null);
    const [open, setOpen] = useState(false);
    const [dialogMsg, setDialogMsg] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setButtonAble(formState.isValid)
    }, [formState.isValid])

    useEffect(() => {
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?hl=${i18n.language === 'he' ? 'iw' : 'en'}`
        window.onSubmit = (value) => {
            setRecaptcha(value);
        };
        document.body.appendChild(script)
    }, [])


    const { t } = useTranslation();
    const onSubmit = (values) => {

        if (recaptcha === null) {
            return
        }
        else {
            setLoading(true);
            setButtonAble(false);
            const data = {
                "clientKey": "H-U3LuRRPm5*+<u+",
                "secret": "\\9<)[x4G@V>p*r)t",
                "reCaptcha": recaptcha,
                "from": values.mail,
                "subject": "הודעה חדשה מאתר שפע בטחון",
                "message": `<div style="direction:rtl"><p>שם מלא: ${values.fullName}</p>
                <p>דוא"ל: ${values.mail}</p>
                <p>מספר טלפון: ${values.tel}</p>`
            }

            return new Promise((resolve, reject) => {
                axios
                    .post(`mail`, data)
                    .then(res => {
                        if (res.data.id === 1) {
                            setDialogMsg(t('FORM_SUCCESS'));
                            setOpen(true);
                            document.getElementById("form").reset();
                            setLoading(false);
                            setButtonAble(true);
                        }
                    })
                    .catch(err => {
                        setDialogMsg(t('FORM_ERR'));
                        setOpen(true);
                        setLoading(false);
                        setButtonAble(true);
                    })
            });
        }
    }

    const theme = createMuiTheme({
        direction: i18n.language === 'he' ? 'rtl' : 'ltr',
        palette: {
            primary: {
                main: '#2395AC'

            },
            secondary: {
                main: '#fff'
            }
        },

    });

    const err = (<span className='text-red-400 text-sm'>{t('REQUIRED')}</span>)
    if (i18n.language === 'en') {
        return (
            <Redirect to="/" />
        )
    }

    return (
        <div>
            <Header imgUrl={headerImg} pageTitle={t('JOBS_PAGE_TITLE')} position="top" />
            {/* <div className={`flex flex-col pt-20 container mx-auto justify-between  ${i18n.language === 'he' ? ' md:flex-row-reverse' : ' md:flex-row'}`}> */}
            <div className='grid grid-cols-1 md:grid-cols-2 gap-12 px-4 container mx-auto py-20 lg:pb-40'>
                <div className='flex flex-col'>
                    <span className='font-bold  text-lg md:text-2xl' style={{ color: appColor }} >{t('JOBS_TITLE')}</span>
                    <span className='md:text-2xl' style={{ color:appColor }}>{t('JOBS_DESC')}</span>
                    <span className='font-bold text-lg md:text-2xl mt-6' style={{ color:appColor }}>{t('JOBS_CONC')}</span>
                </div>
                <ThemeProvider theme={theme}>
                    <div className={`flex ${i18n.language === 'he' ? ' justify-end' : ' '}`}>
                        <form id="form" className='mx-auto' onSubmit={handleSubmit(onSubmit)} >
                            <TextField
                                name="fullName"
                                defaultValue=""
                                variant="outlined"
                                fullWidth
                                inputRef={register({ required: true, })}
                                label={<p className='font-bold' style={{ color: appColor }}>*{t('FullName')}</p>}
                                style={{ marginTop: 12, backgroundColor: 'white' }}
                            />
                            {errors.fullName && err}

                            <TextField
                                name="tel"
                                defaultValue=""
                                variant="outlined"
                                fullWidth
                                inputRef={register({
                                    required: true,
                                    pattern: {
                                        value: /^[0-9]*$/,
                                        message: t('TEL_ERR'),
                                    },
                                })}
                                label={<p className='font-bold' style={{ color: appColor }}>*{t('Tel')}</p>}
                                style={{ marginTop: 12, backgroundColor: 'white' }}
                            />
                            {errors.tel && (errors.tel.message ? <span className='text-red-400 text-sm'>{errors.tel.message}</span> : err)}

                            <TextField
                                name="mail"
                                defaultValue=""
                                variant="outlined"
                                fullWidth
                                inputRef={register({
                                    required: true,
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: t('EMAIL_ERR'),
                                    },
                                })}
                                label={<p className='font-bold' style={{ color: appColor }}>*{t('Email')}</p>}
                                style={{ marginTop: 12, backgroundColor: 'white' }}
                            />
                            {errors.mail && (errors.mail.message ? <span className='text-red-400 text-sm'>{errors.mail.message}</span> : err)}

                            <div
                                className="g-recaptcha mt-4"
                                data-sitekey={captchaKey}
                                data-callback="onSubmit"
                            >
                                {submitClicked && recaptcha === null && <p className='text-red-400 text-sm'>שדה זה הינו חובה</p>}
                            </div>

                            <Button
                                className='w-full focus:outline-none'
                                style={{backgroundColor: buttonAble?appColor:'#d4d4d4', marginTop:30 }}
                                type="submit"
                                disabled={!buttonAble}
                                onClick={() => setSubmitClicked(true)}>
                                <p className='flex justify-center text text-lg py-2 onhover'>{t('send')}</p>
                                {loading && <CircularProgress color="primary" className='mx-4' size={20} />}
                            </Button>
                        </form>
                        <Dialog
                            open={open}
                            onClose={() => setOpen(false)}
                            className='flex self-center justify-center'
                        >
                            <div onClick={() => setOpen(false)} className='w-64 h-40 m-auto flex flex-col justify-center'>
                                <p className='text-gray-700 m-auto font-bold'> {dialogMsg} </p>
                            </div>
                        </Dialog>
                    </div>
                </ThemeProvider>
            </div>
        </div>
    )
}

export default Jobs;