import React, { useState, useEffect } from 'react';
import Card from './../Header/Card';
import logo from './../../assets/WhiteLogo.png';
import Modal from '@material-ui/core/Modal';
import { useTranslation } from 'react-i18next';
import ChangeLang from './../ChangeLang';
import { withRouter } from 'react-router-dom';
import MenuIcon from './../../assets/menu-24px (1).png';
import closeIcon from './../../assets/icons/close.svg';
import AppBar from '@material-ui/core/AppBar';
import Zoom from '@material-ui/core/Zoom';
import menuTop from './../../assets/Elements/Menu_Path_Up.svg';
import menuBottom from './../../assets/Elements/Menu_Path_Down.svg';
import i18n from './../../i18n';
import heImg from './../../assets/he.png';
import enImg from './../../assets/en.png';

const Header = (props) => {
    const [showAppBar, setShowAppBar] = useState(false);
    useEffect(() => {
        const listenToScroll = () => {
            const winScroll = document.body.scrollTop || document.documentElement.scrollTop
            const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            const scrolled = winScroll / height;
            const isNotAtTop = scrolled > 0;

            if (showAppBar !== isNotAtTop)
                setShowAppBar(isNotAtTop);
        }
        window.addEventListener('scroll', listenToScroll);
        return () => window.removeEventListener('scroll', listenToScroll);
    });

    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const style = {
        backgroundImage: `url('${props.imgUrl}')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: props.position ? props.position : 'center',
        width: '100%',
        backgroundColor: props.filter ? 'rgba(0,0,0,0.5)' : '',
        backgroundBlendMode: 'multiply'
    };

    const clickHandler = () => {
        setOpen((prev) => !prev);
    }
    const menuItemClicked = (to) => {
        setOpen((prev) => !prev)
        window.scrollTo(0, 0);
        props.history.push(`/${t(to)}`);
    }
    const menuItems = [
        { item: 'Home', link: 'MenuHomePage' },
        { item: 'About', link: 'MenuAboutPage' },
        { item: 'Services', link: 'MenuSecureServPage' },
        { item: 'MassEvents', link: 'MenuMassEventsPage' },
        i18n.language === 'he' && { item: 'Technology', link: 'MenuTechnologyPage' },
        i18n.language === 'he' && { item: 'Specializations', link: 'MenuSpecializationsPage' },
        i18n.language === 'en' && { item: 'Command&Control', link: 'MenuCommandPage' },
        i18n.language === 'he' &&  { item: 'JOBS', link: 'JOBS' },
        { item: 'ContactUs', link: 'MenuContactPage' }

    ];
    const body = (
        <div className='h-full w-full focus:outline-none flex' style={{ backgroundColor: "rgba(30, 52, 91, 0.88)" }}>
            <div className='m-auto self-center w-full pb-20 md:pb-6'>
                <div className='flex justify-end w-4/5 m-auto'>
                    <img onClick={clickHandler} className='w-12 lg:w-20 md:absolute cursor onhover' src={closeIcon} alt='' />
                </div>
                <div className='flex flex-col'>
                    <img src={menuTop} alt='' className='mx-auto w-1/2 md:w-1/5 animationTop' />
                    <div className='flex items-center justify-center '>
                        <div className='w-full'>
                            <div className=''>
                                {menuItems.map(item =>
                                    <p key={Math.random()} onClick={() => menuItemClicked(item.link)} className='text text-center menuTitle cursor bold onhoverMenu uppercase'>{t(item.item)}</p>
                                )}
                            </div>
                            <div onClick={clickHandler}>
                                <ChangeLang />
                            </div>
                        </div>
                    </div>
                    <img src={menuBottom} alt='' className='mx-auto w-1/2 md:w-1/5 animationBottom' />
                </div>
            </div>
        </div>
    );

    const iconClick = () => {
        window.scrollTo(0, 0);
        props.history.push(`/בית`);
    }
    const flagClick = (l) => {
        let lang = l === 'he' ? 'en' : 'he';
        let dir = 'ltr';
        if (lang === 'he') dir = 'rtl'
        localStorage.setItem('lang', lang);
        localStorage.setItem('dir', dir);
        i18n.changeLanguage(lang);
        document.body.style.direction = dir;
    }

    return (
        <div className='header' style={style}>
            <div className='w-4/5 mx-auto'>
                <div className='py-6 flex justify-between'>
                    <div className='flex'>
                        <img onClick={iconClick} className='w-16 h-16 md:w-24 md:h-20 cursor onhoverMenu' style={{ filter: 'drop-shadow(15px 0 0.5rem #000)' }} src={logo} alt='' />
                        <img onClick={() => flagClick(i18n.language)} className='h-4 md:h-8 cursor onhoverMenu self-center mx-8 mb-2' src={i18n.language === 'he' ? enImg : heImg} alt='' />
                    </div>
                    <img onClick={clickHandler} className='w-12 h-12 md:w-20 md:h-20 cursor onhoverMenu' src={MenuIcon} alt='' />
                </div>
                <div className='m-auto'>
                    {props.card && <Card />}
                </div>
            </div>

            <Zoom in={showAppBar}>
            <AppBar style={{ backgroundColor: 'rgba(0,0,0,0.65)' }}>
                <div className='w-full mx-auto py-2' >
                    <div className='flex justify-between m-auto w-4/5'>
                        <div className='flex'>
                            <img onClick={iconClick} className='w-16 h-16 md:w-24 md:h-20 cursor onhoverMenu' src={logo} alt='' />
                            <img onClick={() => flagClick(i18n.language)} className='h-4 md:h-8 cursor onhoverMenu self-center mx-8 mb-2' src={i18n.language === 'he' ? enImg : heImg} alt='' />
                        </div>

                        <img onClick={clickHandler} className='w-12 h-12 md:w-20 md:h-20 cursor onhoverMenu' src={MenuIcon} alt='' />
                    </div>
                </div>
            </AppBar>
        </Zoom>
            {props.pageTitle && <p className={`text-white font-bold text-3xl sm:text-4xl md:text-6xl flex justify-center pt-40 xl:pt-64 bold uppercase`}>{props.pageTitle}</p>}
            <Modal
                open={open}
                onClose={clickHandler}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    )
}

export default withRouter(Header);