
import i18n from "i18next";
import { initReactI18next } from "react-i18next";


const resources = {
    en: {
        translation: {
            "ourCustomers": "Our customers",
            "showCustomers": "Show customers",
            'Home': "Home",
            'About': "About",
            'Services': "Security Services",
            'MassEvents': "Public & Mega Events",
            'Technology': "Command & Control",
            'Specializations': "Specializations",
            'ContactUs': "Contact us",
            "questions": "אנו נשמח לענות לשאלותכם",
            "HomePageInfo": ` Shefa Security delivers end-to-end global security services. With over 50 years of accumulated experience, we provide end-to-end comprehensive security solutions`,
            "AboutPageInfo": `Shefa Security delivers end-to-end global security services. With over 50 years of accumulated experience, our expert team comprises of top experts who apply their extensive knowhow and experience to serve civilian and Home Land Security challenges. Combined with top technology, Shefa Security provides a unique multi-dimensional security approach and implementation with military precision.`,
            "AboutPagePointsTitle": "Our Comprehensive Security Solutions: ",
            "AboutPagePoint1": "Security Assessment and Analysis ",
            "AboutPagePoint2": "Developing and Implementing a Security Plan ",
            "AboutPagePoint3": "Physical Security and Access Control ",
            "AboutPagePoint4": "Securing Knowledge & Data ",
            "AboutPagePoint5": "Personnel Security ",
            "AboutPagePoint6": "Roles/Responsibilities/Legal Authorities ",
            "AboutPagePoint7": "Courses & Training ",
            "AboutPagePoint8": "Security Command & Control Development and Implementation ",
            "AboutPagePoint9": "Cyber Security and Platform",
            "AboutPageShortInfo": "Leveraging Your Security to a New Standard",
            "SecurityPageShortInfo": `Full Situation Control at All Times`,
            "MassEventsPageShortInfo": `When Public Safety is Mission Critical From the Start`,
            "MassEventsPageInfo": `<p>Shefa Security is positioned as Israel's exclusive mega event security company.
            A mega event crowd can range from a few thousands to hundreds of thousands of people.</p>
             These often include VIPs, vast media coverage, multi-nationalities, high profile individuals that only increase the caliber of each event.  <br/> 
            Mega events can pose a target for hooligans, hostile forces and provocations that must be managed and controlled at all times. Based on 50 years of experience that have been developed and perfected throughout the years our end-to-end security solution includes:
            `,
            "MassEventsPagePointsTitle": "",
            "MassEventsPagePoint1": "Developing a security plan",
            "MassEventsPagePoint2": "Site threats, hazard identification and analysis",
            "MassEventsPagePoint3": "Creating a BOM (Bill of Material) quantities and specifications for: personnel, fences, queues navigators, barriers, check points, technology etc.",
            "MassEventsPagePoint4": "Crowd control and management expertise",
            "MassEventsPagePoint5": "Full connectivity and synchronization with all security and first response forces",
            "MassEventsPagePoint6": "Communications throughout the process with local authorities",
            "MassEventsPagePoint7": "Permits: police, rescue forces",
            "MassEventsPagePoint8": "Command and control characterization",
            "MassEventsPagePoint9": "Work and security policies planning and implementation",
            "MassEventsPagePoint10": "",
            "MassEventsPagePoint11": "",
            "MassEventsPagePoint12": "",
            "MassEventsPagePoint13": "",
            "MassEventsPagePoint14": "",
            "MassEventsPagePoint15": "",
            "TechnologyPageShortInfo": "שליטה ובקרה מרחבית 360°",
            "CommandPageShortInfo": "Full Situation Control at All Times",


            "ControlTitle1": `Outperforming Any Situation`,
            "ControlInfo1": `Security is only as strong as the platform it's on. Connectivity is the name of the game. Delivering a 360° security solution from air, land and sea requires full synchronization between all personnel, technology, units, command center and HQ to work as a unified entity. It is this platform that enables full situation awareness in real-time at all times.
            At Shefa Security we provide a secured, scalable platform designed to intercept cyber- attacks in real-time as well as integrate any technology and device to accommodate any situation on-the-move.`,
            'Control1PointsTitle': ' Our 360° security solution ensures a strong, resilient and agile command & control center that delivers:',
            'Control1Point1': 'Full situation control at all times',
            'Control1Point2': 'All units and teams working as a unified entity',
            'Control1Point3': 'Platform authorization based on security clearance and profile',
            'Control1Point4': 'Threat Prevention',
            'Control1Point5': 'Multiple-event monitoring, control and management ',
            'Control1Point6': 'Crowd Management',
            'Control1Point7': 'Emergency and evacuation management',
            'Control1Point8': 'Coordination and communications with local authorities',
            'Control1Point9': 'Disaster Recovery',
            'Control1Point10': 'Communication continuity in crises and natural disaster',



            "ControlTitle2": `Agile Vision Ahead`,
            "ControlInfo2": `Shefa Security utilizes its fully autonomous drone fleet for safe cities, industrial parks, agriculture and onsite missions and tasks. Our drone fleet come complete with an automatic recharge docking station that is fully protected to withstand all weather and harsh condition thus reducing operational costs, manpower and maintenance.`,
            'Control2PointsTitle': `Shefa Security's Drone Fleet Operational Highlights:`,
            'Control2Point1': 'Fully autonomous',
            'Control2Point2': 'Remote Operations and supervision on multiple sites',
            'Control2Point3': 'Full alert system',
            'Control2Point4': 'Highest accuracy',
            'Control2Point5': 'Regular site supervision and inspection',
            'Control2Point6': 'Lowest maintenance',
            'Control2Point7': 'Minimal manpower',
            'Control2Point8': 'Cost efficiency',
            'Control2Point9': 'Full site visibility',
            'Control2Point10': 'Tailored solutions',



            "ControlTitle3": `A New Urban Security Standard`,
            "ControlInfo3": `Shefa Security delivers a  360°  cloud based information and communication technologies (ICT) solution for safe cities. From technology to connectivity, personnel and remote full command and control operation center, Shefa Security deploys a cloud based seamless surveillance systems, CCTVs, a full fleet of drones, gunshot detectors, sensors platform and more to provide:`,
            // 'Control2PointsTitle':`Shefa Security's Drone Fleet Operational Highlights:`,
            'Control3Point1': 'Full situation awareness in real time',
            'Control3Point2': 'Fast response to critical situations',
            'Control3Point3': 'Zero blind spots',
            'Control3Point4': 'Street level data on crime',
            'Control3Point5': 'Real time alerts',
            'Control3Point6': 'Event and video search',
            'Control3Point7': 'Pattern detection',
            'Control3Point8': 'Smart and actionable decision making',
            'Control3Point9': 'Riot and crowd control',
            'Control3Point10': 'Incident management',
            'Control3Point11': 'Command viewing centers',
            'Control3Point12': 'Personal safety',
            'Control3Point13': 'Gender safety',




            'SeaPortsInfo': "Shefa Security provides a Multi-Dimensional, encompassing security solution that enhances any seaport and industrial park into a Smart-Secured zone without affecting, interfering or interrupting regular activities. This includes:",
            "IndustrialParks": "Industrial Parks:",
            "IndustrialParksPoint1": "Security Survey and Risk Assessment",
            "IndustrialParksPoint2": "Developing and Implementing a Security Plan",
            "IndustrialParksPoint3": "Physical Security and Access Control",
            "IndustrialParksPoint4": "Security, Knowledge & Data",
            "IndustrialParksPoint5": "Personnel Security",
            "IndustrialParksPoint6": "Cyber Security",
            "SeaPort": "Sea Port:",
            "SeaPortPointsTitle": "All above services",
            "SeaPortPoint1": "Personnel training and development of a SWAT TEAM to handle:",
            "SeaPortPoint2": "End-to-End Cargo Supply Chain Management",
            "SeaPortPoint3": "Scalable platform seamless integration of multiple aspects and technologies to deliver a continuous, constant flow of data from command post to HQ",
            "SeaPortPoint4": `Virtual Fence" detects water and land intrusions before reaching the fence`,
            "SeaPortPoint5": "Intrusion detection by means of cutting-edge technologies",
            "SeaPortPoint6": "Detection of floating obstacles technology",


            "SeaPortPoint1SubPoint1": "Maritime Terrorism",
            "SeaPortPoint1SubPoint2": "Drug Smuggling",
            "SeaPortPoint1SubPoint3": "Stowaways and Alien Smuggling",
            "SeaPortPoint1SubPoint4": "Crowd Management ",

            "SeaPortPoint2SubPoint1": "From Vessel Docking to Final Delivery.",
            "SeaPortPoint2SubPoint2": "Top Technologies to ensure the integrity of cargo shipments",
            "SeaPortPoint2SubPoint3": "Cargo Tracking,Loading & Unloading",
            "SeaPortPoint2SubPoint4": "Vessel Cargo Weight Distribution & Balance",




            "engTrainingPointsTitle": "Among our training courses are:",
            "engTrainingPoint1": "Crowd Control and Management",
            "engTrainingPoint2": "Emergency Public Routing",
            "engTrainingPoint3": "Security Officers Training",
            "engTrainingPoint4": "VIP Convoy and Bodyguard",
            "engTrainingPoint5": "Delegate Security",
            "engTrainingPoint6": "Mega Events Control and Management",
            "engTrainingPoint7": "Front Stage Security for Mega Events",
            "engTrainingPoint8": "Team Leader Preparation",
            "engTrainingPoint9": "SWAT Team Development and Training",
            "engTrainingPoint10": "Schools and Public Buildings Security",
            "engTrainingPoint11": "National Sports & Football Security Planning & Implementation",
            "engTrainingPoint12": "Crowd Assessment and Analysis of Suspicious Individuals",
            "engTrainingPoint13": "Crowd Profiling",


            "TechPagePointsTitle": "",
            "TechPagePoint1": "",
            "TechPagePoint2": "",
            "TechPagePoint3": "",
            "TechPagePoint4": "",
            "TechPagePoint5": "",
            "TechPagePoint6": "",
            "TechPagePoint7": "",
            "TechPagePoint8": "",
            "TechPagePoint9": "",
            "TechPagePoint10": "",
            "send": "Send",
            "FullName": "Full Name",
            "Email": `Email`,
            "Text": "Topic",
            "ContactUsIn": "Contact us",
            "tel": "Tel",
            "mail": "Mail",
            "addr": "Address",
            "address": "34 Yizhak Sadeh, Tel Aviv",
            "CustomersPageShortInfo": "חברות המובילות בארץ שקלו את שירות של חברת שפע ביטחון",
            "CustomersPageInfo": `המוניטין הבינלאומי שצברה החברה יצר את הבחירה הטבעית בקרב גופים וארגונים בינלאומיים לאירועים בעלי פרופיל גבוה בארץ כגון`,
            "CustomersPagePoint1": "אירוויזיון 2019",
            "CustomersPagePoint2": `יום השואה הבינלאומי – רשימת האורחים כללה 49 ראשי מדינות ומכובדים ובכללם: נשיא ברה"מ ולאדימיר פוטין, הנסיך צ'ארלס, ראש ממשלת גרמניה אנגלה מרקל ועוד רבים`,
            "CustomersPagePoint3": "ועידת הנשיא בירושלים – 2009-2014",
            "CustomersPagePoint4": `כנס יונסקו – מטעם רשות ני"ע (מתקיים כל שנה במיקום עולמי אחר)`,
            "CustomersPagePoint5": "כנס UEFA השנתי",
            "CustomersPagePoint6": "כנס OECD",
            "CustomersPagePoint7": "מרוץ ג'ירו דאיטליה",
            "CustomersPagePoint8": "חברות היי טק מבנה תעשיה",
            "CustomersPagePoint9": "מפעלים ופארקי תעשיה",
            "CustomersPagePoint10": "חברות בינלאומיות בארץ ובעולם",
            "CustomersPagePoint11": "גמר הפיינל פור באירופה מטעם היורוליג",
            "SpecializationsPageShortInfo": `חברת שפע ביטחון בע"מ גאה להתמחה בתחומים משלימים המספקים ללקוחותינו פתרונות מלאים מקצה לקצה`,
            "InvestigationServices": 'שירותי חקירות',
            "moreInfo": "More info",
            "trainingDivision": "אגף ההדרכה של חברת שפע בטחון",
            "InvestigationSeviceInfo": "",
            "SpecializationsInfoPoint1": "מכון פוליגרף",
            "SpecializationsInfoPoint2": "בדיקות מהימנות עובדים",
            "SpecializationsInfoPoint3": "חקירות כלכליות",
            "SpecializationsInfoPoint4": "מודיעין עסקי",
            "SpecializationsInfoPoint5": "ריגול תעשייתי",
            "SpecializationsInfoPoint6": "בדיקות האזנות ויעוץ בנושא, התקנות ציוד כנגד האזנות",
            "SpecializationsInfoPoint7": "מעקבים ותצפיות",
            "TrainingInfo": `חברת שפע בטחון בע"מ מכשירה את כוחותיה בעצמה ובכך מבטיחה כי כל אנשי הצוות שלה אכן עומדים בסטנדרטים הגבוהים ביותר. הסגל במכללת שפע מורכב ממיטב המדריכים, יוצאי יחידות מובחרות ובוגרי מערכי אבטחה בטחוניים. כל ההדרכות מלוות במטווחי ירי מכלי נשק שונים בשירות האזרחי.`,
            "TrainingInfo2": `בשנים האחרונות חברת שפע בטחון בע"מ מרצה ומרכז מקצועית במכללת אריאל המכשירה מנהלי בטחון באירועים תחת כיפת השמיים ואירועי ספורט, בנוסף מספקת שירותי הדרכה בקורס מנהלי בטחון, בניהול אירועים אלה.`,
            "TrainingPointsTitle": `ההדרכות כוללות:`,
            "TrainingInfoPoint1": "הדרכה בטיפול בקהל המונים",
            "TrainingInfoPoint2": "הדרכה בניתוב והרגעת קהל במצבי לחץ",
            "TrainingInfoPoint3": "הדרכה באיבחון ותצפיות לעבר קהל לאיתור חשד בטחוני",
            "TrainingInfoPoint4": "הדרכת סדרני בטיחות בכדורגל",
            "TrainingInfoPoint5": "הדרכה לאבטחת קידמת במה במופעי ענק",
            "TrainingInfoPoint6": "הדרכה באבטחת אישים ומשלחות",
            "TrainingInfoPoint7": "הדרכת קציני ביטחון",
            "TrainingInfoPoint8": "הדרכה באבטחת מוסדות ציבוריים",
            "TrainingInfoPoint9": "הדרכה בהכנת ראשי צוותים",
            "TrainingInfoPoint10": "הדרכה בבניית צוותי התערבות למענה בעיתות חירום",
            "TrainingInfoPoint11": "הדרכת נהגים מבצעיים",
            "TrainingInfoPoint12": "הדרכה בעזרה ראשונה",
            "SecureServicesInfo": "VIP Security & VIP Convoys",
            "StationaryServicesPointsTitle": `עם 40 שנות ניסיון וידע מצטבר בשילוב טכנולוגיה פורצת דרך, מספקת החברה פתרונות אבטחה רב מערכתיים 360° כולל פתרונות סייבר עבור המגזרים הבאים:`,
            "StationaryServicesPointsTitle2": `השירות כולל מגוון פתרונות המותאמים באופן אישי לצרכי הלקוח:`,
            "StationaryServicesInfoPoint1": "חברות היי-טק",
            "StationaryServicesInfoPoint2": "משרדים ממשלתיים",
            "StationaryServicesInfoPoint3": "סקטור פרטי",
            "StationaryServicesInfoPoint4": "בתי מלון",
            "StationaryServicesPoint1": "פיקוח פיזי שוטף",
            "StationaryServicesPoint2": "מוקד מבצעי פעיל 24 שעות ביממה בכל ימות השנה",
            "StationaryServicesPoint3": "מערכות בקרה וטכנולוגיה מתקדמות המבקרות את ביצועי השירות",
            "StationaryServicesPoint4": "בניית תיק שטח, כתיבת נהלי עבודה ומתן הדרכות שוטפות",
            "StationaryServicesPoint5": "ניהול מערך קהל/ קבלת קהל במשרדים נותני שירות",
            "StationaryServicesPoint6": "מערך מאבטחים/ות בכל הרמות",
            "StationaryServicesPoint7": "מערכת בקרת סיורים אלקטרוניים – כל סיור מתועד",
            "StationaryServicesPoint8": "קציני ביטחון המוכשרים באגף ההדרכה של החברה",
            "StationaryServicesPoint9": "יחידת אופנוענים",
            "StationaryServicesPoint10": "פקידי קבלה לסביבה משרדית",
            "StationaryServicesPoint11": "בודקים ביטחוניים",
            "StationaryServicesPoint12": "סדרנים",
            "SecureServicesPageBizTitle": "Airports	",
            "SecureServicesPageBizModalTitle": "Airports are the main gateway to any nation, thus they are part of any national mission-critical infrastructure. With years of experience and worldwide civil aviation security background, we plan and implement airport security as an end-to-end state of the art structure. As such, our security layer approach and precision, assure full security layout in all aspects of an airport's life and activity.",
            "bizSecurityPoint1": "Passenger Security Check",
            "bizSecurityPoint2": "Luggage Security Check",
            "bizSecurityPoint3": "Peripheral Security",
            "bizSecurityPoint4": "Cyber Security",
            "bizSecurityPoint5": "",
            "bizSecurityPoint6": "",
            "bizSecurityPoint7": "",
            "bizSecurityPoint8": "",
            "bizSecurityPoint9": "",
            "bizSecurityPoints2Title": "Additional Security Aspects:",


            "bizSecurity1": '"Red Team" tools to evaluate the changes in the airport security output',
            "bizSecurity2": "Courses & Training",
            "bizSecurity3": "Air Marshals",
            "bizSecurity4": "Behavioral Detection Officers",
            "bizSecurity5": "Command & Control",
            "bizSecurity6": "Response Teams Development & Training",
            "bizSecurity7": 'Securing Aircrafts Defined as "High Risk"',
            "bizSecurity8": "Insider Threat - Threat detection from within airport personnel",
            "SecurityServiceGroup": "Training",
            "GroupsSecurityModalText1": `Training the Best with The Best`,
            "GroupsSecurityModalPointsTitle": "We are proud to have among our staff Israel's finest security coaches and trainers. All our trainers are former IDF elite special unit officers with extensive tactical and on-site implementation experience. Together we have developed an encompassing training programs and workshops that can be specifically tailored for the client",
            "GroupsSecurityModalPoint1": "",
            "GroupsSecurityModalPoint2": "",
            "GroupsSecurityModalPoint3": "",
            "GroupsSecurityModalPoint4": "",
            "GroupsSecurityModalPoint5": "",
            "GroupsSecurityModalText2": `השירות כולל: טיפול מלא מדלת המטוס ועד החזרה , רכבי יוקרה, מסוקים, מטוסים, שירות בטרקליני האירוח, מדריכי טיולים ועוד. צוות אבטחת משלחות, אשים ואח"מים מנוהל על ידי בוגרי יחידות עלית וארגוני בטחון מהמובילים בארץ ובחו"ל.`,
            "FarmModalText": "Sea Ports & Industrial Parks",
            "FarmModalInfo": "Shefa Security provides a multi-dimensional, encompassing security solution that enhances any seaport and industrial park into a smart-secured zone without affecting, interfering or interrupting regular activities. This includes:",
            "SecurityFarmModalTitle1": "Industrial Parks:",
            "SecurityFarmModalTitle2": "Seaport:",
            "SecurityFarmModalText1": ``,
            "SecurityFarmModalText2": ``,
            "SecurityFarmModalPoint1": ``,
            "SecurityFarmModalPoint2": ``,
            "SecurityFarmModalPoint3": ``,
            "SecurityFarmModalPoint4": ``,
            "SecurityFarmModalPoint5": ``,
            "SecurityFarmModalPoint6": ``,
            "SecurityFarmModalPoint7": ``,
            "HomePageHeaderText": "",
            "HomePageLinkText": "more info",

            'MenuHomePage': "Home",
            'MenuAboutPage': "About",
            'MenuSecureServPage': "Security-Services",
            'MenuMassEventsPage': "Public-Events",
            'MenuTechnologyPage': "/",
            'MenuSpecializationsPage': "/",
            'MenuCommandPage': 'Command-And-Control',
            'MenuContactPage': "Contact-Us",
            'MenuCustomersPage': "Customers",

            "VipConvoysTitle": "Making Sure Your Most Valuable Assets Are Safe",
            "VipConvoysText": `<p>Securing a VIP convoy or delegation requires a dynamic, 360° approach that covers ground, air, sea, crowd management and suspect detection all on the move. This calls for full operations in multiple circles and patterns that are all synchronized to tactically adjust to any situation that may develop. </p>
            Our experience and tactical understanding of convoy security is why Shefa Security is chosen by global state leaders and officials as well as events of the highest caliber such as: </br>
            <strong>International Holocaust Day</strong> - 49 global state leaders and dignitaries including: </br>
            President Vladimir Putin, Vice President Mike Pence, Prince Charles, 
            Chancellor Angela Merkel and more.</br>
            <strong>Presidential conferences in Israel.</strong>
            `,
            "convoisPointsTitle": "At Sheffa Security we cover all aspects of VIP convoy and delegations:",


            "convoysPoint1": "Onsite Due Diligence",
            "convoysPoint2": "Transportation Planning",
            "convoysPoint3": "Route Planning",
            "convoysPoint4": "Renting all relevant transportation",
            "convoysPoint5": "Forefront Technology",
            "convoysPoint6": "Command & Control Center",
            "convoysPoint7": "Trained professionals specializing in convoys",
            "convoysPoint8": "Communications with local authorities if needed",
            "convoysPoint9": "Unmanned Aerial Vehicles (UAVs)",
            "convoysPoint10": "Emergency & Evacuation",
            "convoysPoint11": "Full communication synchronization",

            "EMAIL_ERR": "email is not valid",
            "REQUIRED": "required field",
            "FORM_SUCCESS": "Your request was sent successfully!",
            "FORM_ERR": "An error has occurred. Please try again",
            "TEL_ERR": "number is not valid",
            "Tel": "Telephone"

        }
    },
    he: {
        translation: {
            "FORM_SUCCESS": "פנייתך נשלחה בהצלחה!",
            "FORM_ERR": "אירעה שגיאה! נסה שנית",
            "EMAIL_ERR": "מייל אינו תקין",
            "REQUIRED": "שדה זה הינו חובה",
            "ourCustomers": "בין לקוחותינו",
            "showCustomers": "הצג לקוחות",
            'Home': "בית",
            'About': "אודות",
            'Services': "שירותי אבטחה",
            'MassEvents': "אבטחת אירועים המוניים",
            'Technology': "מוקד תצפית וטכנולוגיה",
            'Specializations': "התמחויות נוספות",
            'ContactUs': "צור קשר",
            "HomePageLinkText": "למידע נוסף לחץ כאן",
            "HomePageInfo": ` שפע ביטחון בע"מ הוקמה ב 1982 על ידי יהונתן שפע ויוצאי יחידות עילית. החברה מובילה את כל פעילותיה בתפיסת אבטחה ייחודית שפיתחה לאורך 40 שנות ניסיונה. גישה זו מאפשרת גמישות מירבית בשלושה צירים מרכזיים: ניהול סיכונים, טכנולוגיה, שליטה ובקרת נהלים ואיוש של צוותי אבטחה מובילים, בעלי הכשרה מותאמת לכל משימה.`,
            "questions": "אנו נשמח לענות לשאלותכם",
            "AboutPageInfo": `בעמוד הפנימי של אודות - התוכן בפסקה הראשונה אינו תואר את הפיסקה המרכזית (זה שעל רקע הכחול) והוא אמור להיות: שפע ביטחון בע"מ הוקמה ב 1982 על ידי יהונתן שפע ויוצאי יחידות עילית. החברה ובילה את כל פעילותיה בתפישת אבטחה ייחודית שפיתחה לאורך 40 שנות ניסיונה.`,
            "AboutPagePointsTitle": `שפע ביטחון בע"מ מספקת פתרונות שלמים הן בשטח והן ברמת המעטפת הכוללים:`,
            "AboutPagePoint1": "אבטחה באירועים המוניים, בידור, תרבות וספורט",
            "AboutPagePoint2": "אבטחה מרחבית שלמה בכלל ההיבטים: חוות חקלאות, חוות סולאריות, יישובים, פארקי תעשיה ועסקים, מפעלים",
            "AboutPagePoint3": `אבטחה למשלחות, אישים ואח"מים`,
            "AboutPagePoint4": "שירותי אבטחה כלליים",
            "AboutPagePoint5": "אבטחה ניידת, יחידות אופנועים, רכבים",
            "AboutPagePoint6": "אבטחת מידע גלוי וממוחשב - ניהול מערך הסייבר וגישה למערכות המידע של הארגון",
            "AboutPagePoint7": "מערך חקירות",
            "AboutPageShortInfo": "ממנפים את מערך האבטחה והביטחון שלך לסטנדרט חדש",
            "SecurityPageShortInfo": `שירותי האבטחה של חברת שפע בע"מ`,
            "MassEventsPageShortInfo": `חברת שפע ביטחון בע"מ מובילה באופן בלעדי כבר 40 שנה את תחום ניהול אבטחת אירועים המוניים בארץ. במהלך השנים האחרונות ביססה את מעמדה בעולם ויצרה קשרים ושיתופי פעולה בינלאומיים עם חברות מובילות בעולם .`,
            "MassEventsPageInfo": `אנו בשפע ביטחון דוגלים בגישה כי מהרגע בו אנו נבחרנו לנהל אירוע יוכל הלקוח לישון בשקט ולהתפנות לנושאים חשובים אחרים`,
            "MassEventsPagePointsTitle": "אי לכך אנו מספקים פתרונות שלמים משלב תכנון האירוע ועד סיומו כולל:",
            "MassEventsPagePoint1": "בניית תוכנית אבטחה",
            "MassEventsPagePoint2": "ניתוח השטח, האיומים והסיכונים",
            "MassEventsPagePoint3": "הכנת מפרט כמויות ובחירת ספקים: חברות אבטחה , גידור, מחסומים, טכנולוגיה ואמצעים פיזיים נוספים",
            "MassEventsPagePoint4": "התמחות ייחודית בניהול קהל המוני",
            "MassEventsPagePoint5": "חיבור וסנכרון מלא בין כלל גורמי הבטחון וההצלה השונים",
            "MassEventsPagePoint6": "קשר הדוק לאורך התהליך עם גורמי הבטיחות",
            "MassEventsPagePoint7": "תיאום וקבלת אישורים מול כל הגורמים הרלוונטים: משטרה, כוחות הצלחה",
            "MassEventsPagePoint8": "תכנון, אפיון ותפעול חדרי שליטה ובקרה",
            "MassEventsPagePoint9": "בחירת אמצעי האבטחה",
            "MassEventsPagePoint10": "נהלי עבודה",
            "MassEventsPagePoint11": "תכנון ותפעול מערכי הסעים מורכבים",
            "MassEventsPagePoint12": "בחירה, תכנון ופריסת אמצעי התקשורת",
            "MassEventsPagePoint13": "מענה שרידות מבצעית למשבר תקשורת",
            "MassEventsPagePoint14": "טכנולוגיה",
            "MassEventsPagePoint15": "בקרה בשטח",
            "TechnologyPageShortInfo": "שליטה ובקרה מרחבית 360°",
            "TechnologyPageInfo1": `חסן מערך האבטחה תלוי במידה רבה בפלטפורמה הטכנולוגית עליה הוא מושתת בשילוב עם בקרה ותצפית אנושית. חברת שפע ביטחון בע"מ פיתחה את גישת האבטחה המרחבית המספקת פתרונות אבטחה 360° במספר מעגלי אבטחה הכוללת: פתרונות טכנולוגיים ואנושיים הפרושים באופן מקומי תוך גיבוי ממערך תצפיתנים מיומן, ללא תלות במוקד מרכזי.`,
            "TechnologyPageInfo2": `גישה זו מאפשרת שליטה טוטאלית במרחב מוגן ללא קשר לגודל ולמורכבות הפעילות. בנוסף מספקת הגישה מענה מלא באופן מקומי ומאפשרת תגובה מיידית בזמן אמת. אי לכך הפעולה הננקטת הינה אמיתית ,יזומה שוטפת ומסכלת באופן אפקטיבי ביותר.`,
            "TechPagePointsTitle": "תשתית זו מבטיחה חוסן מירבי המספק:",
            "TechPagePoint1": "שליטה מירבית על כל מצב בכל זמן נתון עם יכולת שרידות מבצעית",
            "TechPagePoint2": "ראייה מערכתית",
            "TechPagePoint3": "מערכת מבוססת הרשאות",
            "TechPagePoint4": "תיאום וסנכרון מקסימלי של כל הצוותים בשטח ויחידות הבקרה",
            "TechPagePoint5": "מנגנון למניעת איומים",
            "TechPagePoint6": "יכולת שליטה וניהול של מספר אירועים במקביל",
            "TechPagePoint7": "תוכנות אנליטיקה משוכללות ופתרונות טכנולוגיים מתקדמים",
            "TechPagePoint8": `יכולת שילוב מערכות באמצעות תוכנות שו"ב מתקדמות`,
            "TechPagePoint9": "שימוש במערכות קיימות",
            "send": "שלח",
            "FullName": "שם מלא",
            "Email": `דוא"ל`,
            "Text": "נושא",
            "ContactUsIn": "מוזמנים ליצור קשר ב:",
            "tel": "טל",
            "mail": "מייל",
            "addr": "כתובת",
            "address": "רחוב יצחק שדה 34 תל-אביב",

            "CustomersPageShortInfo": "חברות המובילות בארץ שקלו את שירות של חברת שפע ביטחון",
            "CustomersPageInfo": `המוניטין הבינלאומי שצברה החברה יצר את הבחירה הטבעית בקרב גופים וארגונים בינלאומיים לאירועים בעלי פרופיל גבוה בארץ כגון`,
            "CustomersPagePoint1": "אירוויזיון 2019",
            "CustomersPagePoint2": `יום השואה הבינלאומי – רשימת האורחים כללה 49 ראשי מדינות ומכובדים ובכללם: נשיא ברה"מ ולאדימיר פוטין, הנסיך צ'ארלס, ראש ממשלת גרמניה אנגלה מרקל ועוד רבים`,
            "CustomersPagePoint3": "ועידת הנשיא בירושלים – 2009-2014",
            "CustomersPagePoint4": `כנס יונסקו – מטעם רשות ני"ע (מתקיים כל שנה במיקום עולמי אחר)`,
            "CustomersPagePoint5": "כנס UEFA השנתי",
            "CustomersPagePoint6": "כנס OECD",
            "CustomersPagePoint7": "מרוץ ג'ירו דאיטליה",
            "CustomersPagePoint8": "חברות היי טק מבנה תעשיה",
            "CustomersPagePoint9": "מפעלים ופארקי תעשיה",
            "CustomersPagePoint10": "חברות בינלאומיות בארץ ובעולם",
            "CustomersPagePoint11": "גמר הפיינל פור באירופה מטעם היורוליג",
            "SpecializationsPageShortInfo": `חברת שפע ביטחון בע"מ גאה להתמחה בתחומים משלימים המספקים ללקוחותינו פתרונות מלאים מקצה לקצה`,
            "InvestigationServices": 'שירותי חקירות',
            "moreInfo": "מידע נוסף",
            "trainingDivision": "אגף ההדרכה של חברת שפע בטחון",
            "InvestigationSeviceInfo": `כחברה המספקת מענה אבטחתי שלם עבור לקוחותיה, לשפע ביטחון אגף חקירות הפועל מזה 40 שנה בניצוחו של יהונתן שפע שכיהן בתפקידי ביטחון בכירים ובשירות המדינה. החברה הינה בעלת רישיון חקירות ומפעילה חוקרים בכירים בכל תחומי החקירות. השירות כולל`,
            "SpecializationsInfoPoint1": "מכון פוליגרף",
            "SpecializationsInfoPoint2": "בדיקות מהימנות עובדים",
            "SpecializationsInfoPoint3": "חקירות כלכליות",
            "SpecializationsInfoPoint4": "מודיעין עסקי",
            "SpecializationsInfoPoint5": "ריגול תעשייתי",
            "SpecializationsInfoPoint6": "בדיקות האזנות ויעוץ בנושא, התקנות ציוד כנגד האזנות",
            "SpecializationsInfoPoint7": "מעקבים ותצפיות",
            "TrainingInfo": `חברת שפע בטחון בע"מ מכשירה את כוחותיה בעצמה ובכך מבטיחה כי כל אנשי הצוות שלה אכן עומדים בסטנדרטים הגבוהים ביותר. הסגל במכללת שפע מורכב ממיטב המדריכים, יוצאי יחידות מובחרות ובוגרי מערכי אבטחה בטחוניים. כל ההדרכות מלוות במטווחי ירי מכלי נשק שונים בשירות האזרחי.`,
            "TrainingInfo2": `בשנים האחרונות חברת שפע בטחון בע"מ מרצה ומרכז מקצועית במכללת אריאל המכשירה מנהלי בטחון באירועים תחת כיפת השמיים ואירועי ספורט, בנוסף מספקת שירותי הדרכה בקורס מנהלי בטחון, בניהול אירועים אלה.`,
            "TrainingPointsTitle": `ההדרכות כוללות:`,
            "TrainingInfoPoint1": "הדרכה בטיפול בקהל המונים",
            "TrainingInfoPoint2": "הדרכה בניתוב והרגעת קהל במצבי לחץ",
            "TrainingInfoPoint3": "הדרכה באיבחון ותצפיות לעבר קהל לאיתור חשד בטחוני",
            "TrainingInfoPoint4": "הדרכת סדרני בטיחות בכדורגל",
            "TrainingInfoPoint5": "הדרכה לאבטחת קידמת במה במופעי ענק",
            "TrainingInfoPoint6": "הדרכה באבטחת אישים ומשלחות",
            "TrainingInfoPoint7": "הדרכת קציני ביטחון",
            "TrainingInfoPoint8": "הדרכה באבטחת מוסדות ציבוריים",
            "TrainingInfoPoint9": "הדרכה בהכנת ראשי צוותים",
            "TrainingInfoPoint10": "הדרכה בבניית צוותי התערבות למענה בעיתות חירום",
            "TrainingInfoPoint11": "הדרכת נהגים מבצעיים",
            "TrainingInfoPoint12": "הדרכה בעזרה ראשונה",
            "SecureServicesInfo": `שירותי אבטחה נייחת`,
            "StationaryServicesPointsTitle": `עם 40 שנות ניסיון וידע מצטבר בשילוב טכנולוגיה פורצת דרך, מספקת החברה פתרונות אבטחה רב מערכתיים 360° כולל פתרונות סייבר עבור המגזרים הבאים:`,
            "StationaryServicesPointsTitle2": `השירות כולל מגוון פתרונות המותאמים באופן אישי לצרכי הלקוח:`,
            "StationaryServicesInfoPoint1": "חברות היי-טק",
            "StationaryServicesInfoPoint2": "משרדים ממשלתיים",
            "StationaryServicesInfoPoint3": "סקטור פרטי",
            "StationaryServicesInfoPoint4": "בתי מלון",
            "StationaryServicesPoint1": "פיקוח פיזי שוטף",
            "StationaryServicesPoint2": "מוקד מבצעי פעיל 24 שעות ביממה בכל ימות השנה",
            "StationaryServicesPoint3": "מערכות בקרה וטכנולוגיה מתקדמות המבקרות את ביצועי השירות",
            "StationaryServicesPoint4": "בניית תיק שטח, כתיבת נהלי עבודה ומתן הדרכות שוטפות",
            "StationaryServicesPoint5": "ניהול מערך קהל/ קבלת קהל במשרדים נותני שירות",
            "StationaryServicesPoint6": "מערך מאבטחים/ות בכל הרמות",
            "StationaryServicesPoint7": "מערכת בקרת סיורים אלקטרוניים – כל סיור מתועד",
            "StationaryServicesPoint8": "קציני ביטחון המוכשרים באגף ההדרכה של החברה",
            "StationaryServicesPoint9": "יחידת אופנוענים",
            "StationaryServicesPoint10": "פקידי קבלה לסביבה משרדית",
            "StationaryServicesPoint11": "בודקים ביטחוניים",
            "StationaryServicesPoint12": "סדרנים",
            "SecureServicesPageBizTitle": "פארקי תעשייה ועסקים",
            "SecureServicesPageBizModalTitle": `גישת האבטחה המרחבית של חברת שפע ביטחון מאפשרת לפארקי תעשייה ועסקים להינות ממערך אבטחה גמיש ועצמאי המבוסס על יחידת שליטה ובקרה המקומית שאינה תלוייה במוקד מרכזי. כיחידה עצמאית, מערך האבטחה כולל:`,
            "bizSecurityPoint1": "עזרים טכנולוגיים הפרושים בשטח",
            "bizSecurityPoint2": "כח אדם המותאם לתנאי וצרכי השטח",
            "bizSecurityPoint3": "יחידת אופנוענים",
            "bizSecurityPoint4": "תשתיות טכנולוגיות המבטיחות התנהלות שוטפת בין כל הגורמים גם בעיתות של הפסקות חשמל, אסונות טבע וחוסר זמינות של תשתיות תקשורת בשטח",
            "bizSecurityPoint5": "ניהול מערך קהל/ קבלת קהל במשרדים נותני שירות",
            "bizSecurityPoint6": "מערך מאבטחים/ות בכל הרמות",
            "bizSecurityPoint7": "מערכת בקרת סיורים אלקטרוניים – כל סיור מתועד",
            "bizSecurityPoint8": "קציני ביטחון המוכשרים במכללת שפע לאבטחה",
            "bizSecurityPoint9": "התאמת הגורמים הביטחוניים בהתאם לצרכי הפארק",
            "bizSecurityPoints2Title": "גישה זו מקנה:",
            "bizSecurity1": "שליטה מירבית על כל מצב בכל זמן נתון עם יכולת שרידות מבצעית",
            "bizSecurity2": "ראייה מערכתית",
            "bizSecurity3": "יכולת ניהול מספר מקרים בו זמנית",
            "SecurityServiceGroup": `משלחות, אבטחת אישים ואחמ"ים`,
            "GroupsSecurityModalText1": `היום אבטחת משלחות, אישים ואח"מים הינה מורכבת מתמיד ומצריכה ניסיון ודריכות רבה אל מול איומים וגורמים בלתי צפויים הן מהאוויר ומהיבשה. כל זאת תוך כדי תנועה וסביבה משתנה המצריכה מעטפת טכנולוגית, שליטה ובקרה מהמובילים בשוק.`,
            "GroupsSecurityModalPointsTitle": `באבטחת משלחות ואח"מים בינלאומיים נוסף גם הצורך בתקשורת וסנכרון מלא מול גורמי חוץ. המוניטין הבינלאומי שצברה החברה יצר את הבחירה הטבעית בקרב גופים וארגונים בינלאומיים לאירועים בעלי פרופיל גבוה בארץ כגון:`,
            "GroupsSecurityModalPoint1": "כלל נבחרות הכדורגל הרשמיות בטורנירים השונים",
            "GroupsSecurityModalPoint2": `אבטחת נבחרת ישראל בכדורגל בארץ ובחו"ל`,
            "GroupsSecurityModalPoint3": `ח"כ, שרים ,ראשי ערים ועוד`,
            "GroupsSecurityModalPoint4": "אומנים כגון: מדונה , שרון סטון, רוברט דה נירו, שאקירה, דפש מוד, לואיס פיגו , סטינג ורבים נוספים",
            "GroupsSecurityModalPoint5": `אנשי עסקים ומנכ"לי חברות בינלאומיות`,
            "GroupsSecurityModalText2": `השירות כולל: טיפול מלא מדלת המטוס ועד החזרה , רכבי יוקרה, מסוקים, מטוסים, שירות בטרקליני האירוח, מדריכי טיולים ועוד. צוות אבטחת משלחות, אשים ואח"מים מנוהל על ידי בוגרי יחידות עלית וארגוני בטחון מהמובילים בארץ ובחו"ל.`,
            "FarmModalText": "אבטחת יישובים, חוות חקלאיות וסולאריות",
            "FarmModalInfo": `שטחים חקלאיים נמצאים ברובם הרחק ממרכז הארץ וממרכזי אוכלוסייה גדולים ולרוב מאובטחים על ידי היישובים הסובבים או באופן עצמאי. מערכות ההתרעה או אזעקה המותקנות בשטחי החוות נועדו לרוב למגן שטחים בנויים ואינן תואמות את תנאי השטח החקלאי. אי לכך למעט מתן תחושת ביטחון מוטעית, נשארות החוות חשופות ופגיעות בפני גורמים פליליים ועוינים המשאירים את חותמם במגוון דרכים: מגרימת נזק לשטח עצמו, לגניבת יבול, השחתת ציוד ושריפת השטח כולו.`,
            "SecurityFarmModalTitle1": "אבטחת חוות לגידול קנאביס",
            "SecurityFarmModalTitle2": "חקלאות מוגנת",
            "SecurityFarmModalText1": `קנאביס הינו חומר מבוקש ביותר במיוחד בקרב גורמים פליליים ותופעת הפריצות לחוות קנאביס גדל משנה לשנה. ב-2019 בלבד דווחו עשרות מקרים חריגים. אלה אינם כוללים את מאות המקרים שאינם מדווחים. חוות קנאביס מהווים יעד מועדף על ידי גורמי פשיעה ותנאי השטח לרוב משרתים לטובת גורמים אלה.`,
            "SecurityFarmModalText2": `עם התפתחות השוק והמודעות לאנרגיה סולארית, כך גם הגניבות של פאנלים סולריים לצורך שימוש עצמי. העלייה במספר הפריצות לחוות סולאריות הפכה לתופעה מוכרת בארץ ובעולם ונכון להיום גם חברות הביטוח הגדולות ביותר עומדות חסרות אונים כנגד התופעה.`,
            "SecurityFarmModalText3": `לחברת שפע ביטחון בע"מ למעלה מ- 40 שנות ניסיון של אבטחת שטחים מאתגרים. על בסיס ניסיון זה פיתחה החברה גישה רב-מערכתית המקנה פתרון מלא הן ברמת השטח והן ברמת המעטפת. גישה זו כוללת מספר מעגלי אבטחה:`,
            "SecurityFarmModalPoint1": `אבטחה היקפית`,
            "SecurityFarmModalPoint2": `אבטחה פיזית איוש 24/7`,
            "SecurityFarmModalPoint3": `עמידה בדרישות משטרת ישראל`,
            "SecurityFarmModalPoint4": `פיקוח פיזי שוטף כולל תוכנת בקרה משוכללת`,
            "SecurityFarmModalPoint5": `חיבור למוקד מבצעי של החברה המאויש 24 שעות ביממה בכל ימות השנה`,
            "SecurityFarmModalPoint6": `אמצעי מערכות וטכנולוגיה מתקדמת`,
            "SecurityFarmModalPoint7": `מערך שליטה ובקרה מקומי מקיף`,
            "bizAddr": "שם רחוב כתובת אילת 88000",
            "bizTel": "08-633333 | 08633333",
            "HomePageHeaderText": "הביטחון שלכם לסטמדרט חדש",

            'MenuHomePage': 'בית',
            'MenuAboutPage': 'אודות',
            'MenuSecureServPage': 'שירותי-אבטחה',
            'MenuMassEventsPage': 'אבטחת-אירועים-המוניים',
            'MenuTechnologyPage': 'מוקד-תצפית-וטכנולוגיה',
            'MenuSpecializationsPage': 'התמחויות-נוספות',
            'MenuContactPage': 'צור-קשר',
            'MenuCustomersPage': "בין-לקוחותינו",
            "MenuCommandPage": 'בית',
            "TEL_ERR": "מספר טלפון לא תקין",
            "Tel": "טלפון",

            "JOBS": "דרושים",
            "JOBS_PAGE_TITLE": "קריירה בתחום הביטחון",
            "JOBS_TITLE": "חברת שפע ביטחון מגייסת לאורך השנים כח אדם איכותי.",
            "JOBS_DESC": "למגוון עבודות אבטחה ברחבי הארץ אנחנו מזמינים אתכם להצטרף למשפחה ולעבוד בחברה שבה תקבלו תנאים סוציאלים מהיום הראשון, הכשרות והדרכות על חשבוננו, ביטחון תעסוקתי ואפשרויות קידום נרחבות.",
            "JOBS_CONC": "אז בואו להיות חלק מחברה איכותית ומקצועית השאירו פרטים ונחזור אלכם",
            "JOBS_HOMEPAGE": "קריירה בתחום הביטחון",
            "SIGN": "להרשמה"
        }
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'he',

        keySeparator: false,

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;