import axios from 'axios';

const instance = axios.create({
    //  baseURL: 'https://192.168.1.15:45457/api/v1/'
    baseURL: 'https://api.cdigital.co.il/api/v1/'
});


// const instance = axios.create({
//     baseURL: 'http://192.168.1.15:49383/api/v1/'
// });




export default instance;