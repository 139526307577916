import React from 'react';
import Header from './../Header/Header';
import bgImg from './../../assets/Home/Home-Header.jpg';
import Info from './Info';
import SecurServImg from './../../assets/Home/Home-2.jpg';
import homeImg3 from './../../assets/Home/Home-3.jpg';
import Customers from './Customers';
import homeImg4 from './../../assets/Home/Home-4.jpg';
import homeImg5 from './../../assets/Jobs/homepage.png';
import { withRouter } from 'react-router-dom';
import blackImg from './../../assets/Home/Black.jpg';
import ImgText from './../ImgText';
import ScrollAnimation from 'react-animate-on-scroll';
import i18n from './../../i18n';
import whiteArrow from './../../assets/yellowArrow.png';
import { useTranslation } from 'react-i18next';


const HomePage = (props) => {
    const { t } = useTranslation();

    return (
        <div>
            <Header imgUrl={bgImg} card></Header>
            <Info />
            <div>
                <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} offset={10} delay={0} animateOnce={true}>
                    <div className='colReverse mt-12 w-full' style={{ direction: 'rtl' }}>
                        <div className='background w-full' style={{ backgroundImage: `url('${blackImg}')`, objectFit: 'contain', direction: i18n.language === 'en' ? 'initial' : 'rtl' }}>
                            <ImgText title='MassEvents' linkTo={t('MenuMassEventsPage')} linkTxt={'HomePageLinkText'} right styling={' lg: mr-20 xl:mr-40'} />
                        </div>
                        <div className='background w-full' style={{ backgroundImage: `url('${SecurServImg}')`, direction: i18n.language === 'en' ? 'initial' : 'rtl' }}>
                            <ImgText title='Services' linkTo={t('MenuSecureServPage')} linkTxt={'HomePageLinkText'} />
                        </div>
                    </div>
                </ScrollAnimation>
                <div>
                    <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} offset={10} delay={0} animateOnce={true}>
                        <div style={{ direction: 'rtl' }}>
                            <Customers />
                        </div>
                    </ScrollAnimation>
                </div>
            </div>
            <div id={3} className='md:hidden'>
                <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} offset={10} delay={0} animateOnce={true}>
                    <div className='background w-full flex justify-center' style={{ backgroundImage: `url('${homeImg3}')` }}>
                        <ImgText title='Technology' linkTo={i18n.language === 'en' ? t('MenuCommandPage') : t('MenuTechnologyPage')} linkTxt={'HomePageLinkText'} />
                    </div>
                </ScrollAnimation>
            </div>
            <div className='hidden md:inline'>
                <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} offset={10} delay={0} animateOnce={true}>
                    <div className='colReverse'>
                        <div className='background w-1/2' style={{ backgroundColor: '#2395AC' }}>
                            <ImgText title='Technology' linkTo={i18n.language === 'en' ? t('MenuCommandPage') : t('MenuTechnologyPage')} linkTxt={'HomePageLinkText'} styling={' lg:m-auto'} />
                        </div>
                        <img src={homeImg3} className='background w-1/2' alt='' />
                    </div>
                </ScrollAnimation>
            </div>


            {i18n.language === 'he' && <>
                <div className='md:hidden'>
                    <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} offset={10} delay={0} animateOnce={true}>
                        <div className='background w-full flex justify-center' style={{ backgroundImage: `url('${homeImg5}')`, backgroundPosition:'top' }}>
                            <ImgText title='JOBS_HOMEPAGE' linkTo={t('JOBS')} linkTxt={'SIGN'} />
                        </div>
                    </ScrollAnimation>
                </div>
                <div className='hidden md:inline'>
                    <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} offset={10} delay={0} animateOnce={true}>
                        <div className='colReverse'>
                            <img src={homeImg5} className='background w-1/2 object-top' alt=''/>
                            <div className='background w-1/2' style={{ backgroundColor: '#005567' }}>
                                <ImgText title='JOBS_HOMEPAGE' linkTo={t('JOBS')} linkTxt={'SIGN'} styling={' lg:m-auto'} />
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            </>
            }

            <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} delay={0} animateOnce={true}>
                <div className='contactFooter' style={{ backgroundImage: `url('${homeImg4}')` }}>
                    {i18n.language === 'he' ? <ImgText title='ContactUs' linkTo={t('MenuContactPage')} linkTxt={"questions"} contact />
                        :
                        <div className='h-full flex items-center'>
                            <span onClick={() => { window.scrollTo(0, 0); props.history.push(t(`MenuContactPage`)) }} className='text flex m-auto text-3xl md:text-5xl xl:text-6xl bold leading-none uppercase onhover justify-center flex-center'>{t('ContactUs')}  <img src={whiteArrow} className='imgFlip w-6 lg:w-12 mx-4 flex justify-start' alt='' /></span>
                        </div>}
                </div>
            </ScrollAnimation>

        </div >
    )
}

export default withRouter(HomePage);