import React from 'react';
import Header from '../Header/Header';
import Contact from '../ContactFooter';
import { appColor } from './../../assets/appColor';
import { useTranslation } from 'react-i18next';
import ShortInfo from './../ShortInfo';
import headerImg from './../../assets/Technology/Header.jpg';
import image1 from './../../assets/Technology/d-1.jpg';
import image2 from './../../assets/Technology/techno-1.jpg';
import Points from './../Points';
import ScrollAnimation from 'react-animate-on-scroll';
import image3 from './../../assets/Technology/002.jpg';
import i18n from './../../i18n';
import { Redirect } from 'react-router-dom';

const Technology = (props) => {
    const { t } = useTranslation();
    const points = ["TechPagePoint1", "TechPagePoint2", "TechPagePoint3", "TechPagePoint4", "TechPagePoint5", "TechPagePoint6", "TechPagePoint7", "TechPagePoint8", "TechPagePoint9"];
    return (
        i18n.language === 'he' ?
            <div>
                <Header imgUrl={headerImg} pageTitle={t('Technology')} filter />
                <ShortInfo text={t("TechnologyPageShortInfo")} />
                <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} offset={10} delay={0} animateOnce={true}>
                    <div className='colReverse'  >
                        <div className='w-full lg:w-1/2 flex justify-center'>
                            <ScrollAnimation animateIn='animate__bounceInLeft' duration={1} delay={400} animateOnce={true}>
                                <img src={image3} className='background object-contain' alt='' />
                            </ScrollAnimation>
                        </div>
                        <div className='w-full lg:w-1/2 px-6 lg:px-8' style={{ backgroundColor: appColor }}>
                            <div className=' mx-auto mb-6' >
                                <p className='text modalTitle mt-6' style={{ color: "#fff" }}>{t('TechPagePointsTitle')}</p>
                                {points.map(point =>
                                    <Points key={point} white point={point} />
                                )}
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn='animate__fadeInRight' offset={10} duration={0.5} delay={0} animateOnce={true}>
                    <div className='colReverse'>
                        <div className='md:w-7/12 flex' style={{ backgroundColor: '#002E68' }}>
                            <p className='info py-6 mx-8 md:m-auto md:w-3/5 xl:text-3xl'>{t('TechnologyPageInfo2')}</p>
                        </div>
                        <img src={image2} className='background md:w-5/12' alt='' />
                    </div>
                </ScrollAnimation>

                <ScrollAnimation animateIn='animate__fadeInRight' offset={10} duration={0.5} delay={0} animateOnce={true}>
                    <div className='rowStyle'>
                        <div className='bg-white halfWidth'>
                            <img src={image1} className='w-full background' alt='' />
                        </div>
                        <div className='halfWidth flex justify-end' style={{ backgroundColor: appColor }}>
                            <p className='py-6 info mx-4 md:w-3/4 lg:w-4/5 lg:mx-12 xl:text-3xl'>{t('TechnologyPageInfo1')}</p>
                        </div>
                    </div>
                </ScrollAnimation>
                <Contact bg />
            </div>
            : <Redirect to="/" />
    )
}

export default Technology;