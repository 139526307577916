import React, { useState} from 'react';
import Header from '../Header/Header';
import { useTranslation } from 'react-i18next';
import headerImg from './../../assets/SecureServices/Header.jpg';
import ShortInfo from '../ShortInfo';
import img from './../../assets/Technology/d-1.jpg';
import { Redirect } from 'react-router-dom';
import i18n from './../../i18n';
import img2 from './../../assets/eng/dronsmain.jpg';
import DroneModal from './DroneModal';
import logo from './../../assets/eng/safecities.jpg';
import { appColor } from '../../assets/appColor';
import SafeModal from './SafeModal';
import Contact from '../ContactFooter';
import RemoteModal from './RemoteModal';
import ImgText from '../ImgText';
import Collapse from '@material-ui/core/Collapse';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

const SecureServises = (props) => {
    const { t } = useTranslation();
    const [remoteOpen, setRemoteOpen] = useState(false);
    const [dronOpen, setDronOpen] = useState(false);
    const [safeCitiesOpen, setSafeCitiesOpen] = useState(false);
    const clickModalHandler = (setModalOpen, open, id) => {
        setModalOpen((prev) => !prev);
        if (!open) {
            const el = document.getElementById(id);
            el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    const style = {
        padding: 20,
        backgroundColor: 'rgba(0,0,0,0.65)',
        backgroundBlendMode: 'multiply'
    };


    return (
        i18n.language === 'en' ?
            <div>
                <Header imgUrl={headerImg} pageTitle={'Command & Control'} />
                <ShortInfo text={t("CommandPageShortInfo")} />
                <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} delay={0} offset={10} animateOnce={true} >
                    <div className='background flex' style={{ backgroundImage: `url('${img}')`, backgroundSize: 'cover' }}>
                        <div className='m-auto w-3/4 md:w-1/2 lg:w-2/5 xl:w-1/3 flex items-center' style={style}>
                            <div className='flex items-center px-6'>
                                <ImgText title={'Remote Operation Center'} moreInfo setModal={setRemoteOpen} open={remoteOpen} clickClose={clickModalHandler} id={'Remote-Operation-Center'} styling={' w-3/4 md:w-4/5 lg:w-full lg:px-6 py-4 lg:py-8'} />
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>

                <Collapse in={remoteOpen} timeout={990}><RemoteModal setModal={setRemoteOpen} clickClose={clickModalHandler} id={'Remote-Operation-Center'} /> </Collapse>
                <div className='md:hidden'>
                    <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} delay={0} offset={10} animateOnce={true} >
                        <div className='background flex' style={{ backgroundImage: `url('${img2}')` }}>
                            <div className='m-auto' style={style}>
                                <ImgText title={"Drone Fleet"} moreInfo open={dronOpen} setModal={setDronOpen} clickClose={clickModalHandler} id={2} styling={' w-3/4'} />
                            </div>
                        </div>
                    </ScrollAnimation>
                    <Collapse in={dronOpen} timeout={960}> <DroneModal setModal={setDronOpen} clickClose={clickModalHandler} id={2} /></Collapse>
                </div>

                <div className='hidden md:inline'>
                    <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} delay={0} offset={10} animateOnce={true} >
                        <div className='colReverse'>
                            <img src={img2} className='background w-1/2' alt='' />
                            <div className='background w-1/2 flex' style={{ backgroundColor: appColor }}>
                                <ImgText title={"Drone Fleet"} moreInfo open={dronOpen} setModal={setDronOpen} id={`Drone-Fleet`} clickClose={clickModalHandler} />
                            </div>
                        </div>
                    </ScrollAnimation>
                    <Collapse in={dronOpen} timeout={1000}> <DroneModal setModal={setDronOpen} clickClose={clickModalHandler} id={`Drone-Fleet`} /></Collapse>
                </div>

                <div className='hidden md:inline'>
                    <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} delay={0} offset={10} animateOnce={true} >
                        <div className='flex flex-row-reverse' style={{ backgroundColor: '#F8F8F8' }}>
                            <div className='background w-1/2 flex justify-start'>
                                <ImgText title={"Safe Cities"} infoClr={appColor} moreInfo open={safeCitiesOpen} setModal={setSafeCitiesOpen} id={`Safe-Cities`} clickClose={clickModalHandler} text={'black'} right />
                            </div>
                            <img className='background object-cover w-1/2' src={logo} alt='' />
                        </div>
                    </ScrollAnimation>
                </div>

                <div className='md:hidden'>
                    <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} delay={0} offset={10} animateOnce={true} >
                        <div className='background flex' style={{ backgroundImage: `url('${logo}')` }}>
                            <div className='m-auto' style={style}>
                                <ImgText title={"Safe Cities"} moreInfo open={safeCitiesOpen} setModal={setSafeCitiesOpen} id={`Safe-Cities`} clickClose={clickModalHandler} />
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
                <Collapse in={safeCitiesOpen} timeout={1000}><SafeModal clr={appColor} setModal={setSafeCitiesOpen} clickClose={clickModalHandler} id={`Safe-Cities`} /></Collapse>
                <Contact bg />
            </div>
            : <Redirect to="/" />
    )
}

export default SecureServises;