import React, { useEffect, useState } from 'react';
import styles from './Accessibility.module.css';
import i18next from 'i18next';
import i18Trans from './i18n.json';

const Accessibility = (props) => {
    //const { t } = useTranslation('accessibility');
    const accessibilityParams = {
        fontSizeOffset: 1, 
        readableFont : false,
        filters : {
            'Grayscale' : { value:"grayscale(100%)", isActive: false },
            "Contrast" : { value:"invert(100%)", isActive: false },
            "BlueLight" : { value:"sepia(80%)", isActive: false },
            "LiveColor" : { value:"sepia(80%) hue-rotate(200deg)", isActive: false },
        }
    };

    const [isOpen, setIsOpen] = useState(false);
    const [accParams, setAccParams] = useState(localStorage.getItem('cda-params') ? 
                                                            JSON.parse(localStorage.getItem('cda-params')) :
                                                            accessibilityParams);

    useEffect(() =>
    {
        for (var lang in i18Trans) 
            i18next.addResourceBundle(lang, 'accessibility', i18Trans[lang]);
        
        const html = document.getElementsByTagName("html")[0];
        html.style.filter = '';
        html.style.filter = Object.keys(accParams.filters).map((i) => { 
            return accParams.filters[i].isActive ? accParams.filters[i].value : ""; 
        })
        .join(' ');

        const body = document.getElementsByTagName("body")[0];
        if (accParams.readableFont)
            body.classList.add(`${styles.cdaReadableFont}`);
        else
            body.classList.remove(`${styles.cdaReadableFont}`);
        
        if (accParams.fontSizeOffset > 0)
            body.style.zoom = `${accParams.fontSizeOffset}`;
        else
            body.style.zoom = 0;
        
        localStorage.setItem('cda-params', JSON.stringify(accParams));
    }, [accParams]);

    const openMenuHandler = () => {
        setIsOpen(!isOpen);
    }

    // var textOnly = function () {
    //     if ($('html').hasClass('img-only-active')) {
    //         imgOnly();
    //     }
    //     var active = $('html').hasClass('text-only-active');
    //     // if active == true, remove all custom style and restore originals
    //     if (active)
    //         $('html').removeClass('text-only-active');
    //     else
    //         $('html').addClass('text-only-active');
    //     $('*').not(cdExcept).each(function (a, el) {
    //         if (isImageOrIcon(el)) {
    //             if (active) {
    //                 if ($(el).attr('cd-display') !== undefined) {
    //                     el.style.display = $(el).attr('cd-display');
    //                     el.style.visibility = $(el).attr('cd-vis');
    //                     $(el).removeAttr('cd-display');
    //                     $(el).removeAttr('cd-vis');
    //                 }
    //                 else {
    //                     el.style.visibility = '';
    //                     el.style.display = '';
    //                 }
    //             }
    //             else {
    //                 if (el.style.display !== '' || el.style.visibility !== '') {
    //                     $(el).attr('cd-display', el.style.display);
    //                     $(el).attr('cd-vis', el.style.visibility);
    //                 }
    //                 el.style.display = 'none';
    //                 el.style.visibility = 'hidden';
    //             }
    //         }
    //     });
    // };

    // var imgOnly = function () {
    //     if ($('html').hasClass('text-only-active')) {
    //         textOnly();
    //     }
    //     var active = $('html').hasClass('img-only-active');
    //     // if active == true, remove all custom style and restore originals
    //     if (active) {
    //         $('html').removeClass('img-only-active');
    //     }
    //     else {
    //         $('html').addClass('img-only-active');
    //     }

    //     $('*').not(cdExcept + ', body').each(function (a, el) {
    //         if (isImageOrIcon(el)) {
    //             //console.log(el, el.style.display, el.style.visibility, $(el).css('display'), $(el).css('visibility'));
    //             //console.log(el, el.style.display !== 'none' && el.style.visibility !== 'hidden' && $(el).css('display') !== 'none' && $(el).css('visibility') !== 'hidden');
    //             if (el.style.display !== 'none' && el.style.visibility !== 'hidden' /*&& $(el).css('display') !== 'none' && $(el).css('visibility') !== 'hidden'*/)
    //                 el.style.visibility = active ? '' : 'visible';
    //         }
    //         else
    //             el.style.visibility = active ? '' : 'hidden';
    //     });
    // };

    // var hlLinks = function () {
    //     var active = $('html').hasClass('hl-links');
    //     // if active == true, remove all custom style and restore originals
    //     if (active) {
    //         $('html').removeClass('hl-links');
    //     }
    //     else {
    //         $('html').addClass('hl-links');
    //     }
    //     $('a').each(function () {
    //         if (active) {
    //             this.style.setProperty('background-color', '', '');
    //             this.style.setProperty('color', '', '');
    //         }
    //         else {
    //             this.style.setProperty('background-color', 'yellow', 'important');
    //             this.style.setProperty('color', '#000000', 'important');
    //         }
    //     });
    // };

    // var hlTitles = function () {
    //     var active = $('html').hasClass('hl-titles');
    //     // if active == true, remove all custom style and restore originals
    //     if (active) {
    //         $('html').removeClass('hl-titles');
    //     }
    //     else {
    //         $('html').addClass('hl-titles');
    //     }

    //     $('h1, h2, h3, h4, h5, h6, .title').each(function () {
    //         if (active) {
    //             this.style.setProperty("background-color", "", "");
    //             this.style.setProperty("color", "", "");
    //         }
    //         else {
    //             this.style.setProperty("background-color", "#000000", "important");
    //             this.style.setProperty("color", "#ffffff", "important");
    //         }
    //     });
    // };

    // var disableAnime = function () {
    //     var active = $('html').hasClass('disable-anime');
    //     // if active == true, remove all custom style and restore originals
    //     if (active) {
    //         $('html').removeClass('disable-anime');
    //     }
    //     else {
    //         $('html').addClass('disable-anime');
    //     }
    //     $('*').not(cdExcept).each(function () {
    //         if (active) {
    //             this.style.setProperty("animation-play-state", "", "");
    //             this.style.setProperty("transition-duration", "", "");
    //         }
    //         else {
    //             this.style.setProperty("animation-play-state", "paused", "important");
    //             this.style.setProperty("transition-duration", "0s", "important");
    //         }
    //     });
    // };

    // var largeCursor = function () {
    //     $('html').hasClass('large-cursor') ? $('html').removeClass('large-cursor') : $('html').addClass('large-cursor');
    //     //if ($('html').hasClass('large-cursor')) {
    //     //    $('body')[0].style.setProperty('cursor', '', '');
    //     //    $('html').removeClass('large-cursor');
    //     //}
    //     //else {
    //     //    $('body')[0].style.setProperty('cursor', 'url(' + window.location.origin + '/mekartes/images/social/large_cursor.svg), auto', 'important');
    //     //    $('html').addClass('large-cursor');
    //     //}
    // }

    const setFilter = (name) => {
        setAccParams(prevState => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                [name]: {
                    ...prevState.filters[name],
                    isActive: !prevState.filters[name].isActive
                }
            }
        }));
    }

    const setReadableFont = () => {
        setAccParams(prevState => ({
            ...prevState,
            readableFont: !prevState.readableFont
        }));
    }
    
    const resetFiltersHandler = () => {
        setAccParams(accessibilityParams);
    }

    const gscaleHandler = () => {
        setFilter("Grayscale");
    }

    const contrastHandler = () => {
        setFilter("Contrast");
    }

    const blueLightHandler = () => {
        setFilter("BlueLight");
    }

    const liveColorHandler = () => {
        setFilter("LiveColor");
    }

    const readableFontHandler = () => {
        setReadableFont();
    }

    const enlargeFontHandler = () => {
        if (accParams.fontSizeOffset <= 1.5)
        {
            setAccParams(prevState => ({
                ...prevState,
                fontSizeOffset: prevState.fontSizeOffset + 0.1
            }));
        }
    }
    
    const reduceFontHandler = () => {
        if(accParams.fontSizeOffset >= 1.1)
        {
            setAccParams(prevState => ({
                ...prevState,
                fontSizeOffset: prevState.fontSizeOffset -0.1
            }));
        }
    }

    const resetFHandler = () => {
        if(accParams.fontSizeOffset != accessibilityParams.fontSizeOffset)
        {
            setAccParams(prevState => ({
                ...prevState,
                fontSizeOffset: accessibilityParams.fontSizeOffset
            }));
        }
    }

    return (
        <div className={`${styles.cont} ${isOpen ? styles.active : null}`}>
            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                <span onClick={openMenuHandler} className={styles.menuBtn} style={{ border: `3px solid ${props.borderColor}`,backgroundColor: props.btnBgColor}}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="0 0 48 48"><g><path id="path1" transform="rotate(0,24,24) translate(12.4986559152603,11) scale(0.8125,0.8125)  " fill="#FFFFFF" d="M6.978,10.866L6.978,13.558C4.0870004,14.953 2.2930002,17.645 2.2930002,21.134 2.2930002,25.819001 6.0810001,29.707 10.866,29.707 14.853,29.707 18.243,26.915999 19.139999,23.227L20.436,26.217999C18.642,29.907 15.252,32.000001 10.866,32.000001 4.8850002,31.9 0,27.016 0,21.033999 0,16.349 2.8910003,12.461 6.978,10.866z M10.168,0C11.962,0 13.358,1.396 13.358,3.1900001 13.358,4.5860001 12.461,5.7820001 11.165,6.181L11.065,6.181 11.065,10.966 17.944,10.966 17.944,13.757 11.065,13.757 11.065,15.452 20.635,15.950001 24.723001,26.716 27.513999,25.72 28.311,27.813 23.028,29.807 18.741,18.542001 11.065,18.143 8.1740001,17.944 8.1740001,5.5829999 8.0750001,5.483C7.4760002,4.885 7.0780002,4.087 7.0780004,3.0900002 7.0780002,1.396 8.4730001,0 10.168,0z" /></g></svg>
                </span>
                {isOpen ?
                (<div style={{display:'contents'}}>
                    <span><strong>{i18next.t('accessibility:menu')}</strong></span>
                    <span style={{marginLeft: '10px', cursor: 'pointer'}} onClick={openMenuHandler}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><defs></defs><g transform="translate(-1 -1)"><path style={{fill:'#32074f'}} d="M1.125,0a1.079,1.079,0,0,1,.788.338L6,4.388,10.088.338a1.087,1.087,0,0,1,1.575,0,1.087,1.087,0,0,1,0,1.575L7.575,6l4.087,4.088a1.114,1.114,0,0,1-1.575,1.575L6,7.575,1.913,11.663a1.087,1.087,0,0,1-1.575,0,1.087,1.087,0,0,1,0-1.575L4.425,6,.338,1.913a1.087,1.087,0,0,1,0-1.575A1.079,1.079,0,0,1,1.125,0Z" transform="translate(1 1)" /></g></svg>
                    </span>
                </div>)
                : null }
            </div>
            {isOpen ?
            (<div className={styles.menuContainer}>
                <div className={styles.menuList}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17.567" height="17.567" viewBox="0 0 17.567 17.567"><defs></defs><g transform="translate(-1 -1)"><path style={{fill:'#32074f'}} d="M8.783,1.723V15.845a7.061,7.061,0,1,0,0-14.123ZM8.783,0A8.783,8.783,0,1,1,0,8.783,8.784,8.784,0,0,1,8.783,0Z" transform="translate(1 1)" /></g></svg>
                    <span>{i18next.t('accessibility:colors')}</span>
                    <div className={styles.filter} onClick={gscaleHandler}>
                        <span>{i18next.t('accessibility:grey-cl')}</span>
                        <input type="checkbox" checked={accParams.filters.Grayscale.isActive} onCheck={gscaleHandler} />
                    </div>
                    <div className={styles.filter} onClick={contrastHandler}>
                        <span>{i18next.t('accessibility:contrasting-cl')}</span>
                        <input type="checkbox" checked={accParams.filters.Contrast.isActive} onCheck={contrastHandler} />
                    </div>
                    <div className={styles.filter} onClick={blueLightHandler}>
                        <span>{i18next.t('accessibility:blue-light-filter')}</span>
                        <input type="checkbox" checked={accParams.filters.BlueLight.isActive} onCheck={blueLightHandler} />
                    </div>
                    <div className={styles.filter} onClick={liveColorHandler}>
                        <span>{i18next.t('accessibility:live-cl-filter')}</span>
                        <input type="checkbox" checked={accParams.filters.LiveColor.isActive} onCheck={liveColorHandler} />
                    </div>

                    <span style={{textDecoration:'underline', cursor:'pointer'}} onClick={resetFiltersHandler}>{i18next.t('accessibility:reset')}</span>
                </div>
                <div className={styles.menuList}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17.567" height="17.567" viewBox="0 0 17.567 17.567"><defs></defs><g transform="translate(-3.3 -1)"><path style={{fill:'#32074f'}} d="M6.628,5.839,4.33,11.8H8.861ZM7.966,0h.21l5.4,14.2a8.816,8.816,0,0,0,1.271,2.54,1.6,1.6,0,0,0,1.059.463v.473H8.661V17.2h.3a2,2,0,0,0,1.225-.282.726.726,0,0,0,.245-.588,1.788,1.788,0,0,0-.065-.473c-.017-.076-.127-.394-.333-.96l-.794-2.156H3.943l-.63,1.684a4.244,4.244,0,0,0-.308,1.394A1.206,1.206,0,0,0,3.514,16.9a3.8,3.8,0,0,0,1.469.306v.473H0v-.473a2.156,2.156,0,0,0,1.328-.773,10.726,10.726,0,0,0,1.28-2.613Z" transform="translate(3.3 1)" /></g></svg>
                    <span>{i18next.t('accessibility:fonts')}</span>
                    <div onClick={enlargeFontHandler}>
                        <span>{i18next.t('accessibility:larger-font')}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><defs></defs><g transform="translate(-1 -1)"><path style={{fill:'#32074f'}} d="M5.625,0h.75V5.625H12v.75H6.375V12h-.75V6.375H0v-.75H5.625Z" transform="translate(1 1)" /></g></svg>
                    </div>
                    <div onClick={reduceFontHandler}>
                        <span>{i18next.t('accessibility:smaller-font')}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="0.75" viewBox="0 0 12 0.75"><defs></defs><g transform="translate(-1 -6.625)"><path style={{fill:'#32074f'}} d="M12,5.625v.75H0v-.75Z" transform="translate(1 1)" /></g></svg>
                    </div>
                    <div onClick={resetFHandler}>
                        <span>{i18next.t('accessibility:normal-font')}</span>
                    </div>
                    <div onClick={readableFontHandler}>
                        <span>{i18next.t('accessibility:readable-font')}</span>
                        <input type="checkbox" checked={accParams.readableFont} onCheck={readableFontHandler} />
                    </div>
                </div>
            </div>)
            : null }        
        </div>
    )
}

export default Accessibility;