import React from 'react';
import { useTranslation } from 'react-i18next';
import arrow from './../assets/Group128.png';
import { appColor } from '../assets/appColor';
import { withRouter } from 'react-router-dom';
import icon from './../assets/icons/Contact.svg';
import i18n from './../i18n';


const Contact = (props) => {
    const { t } = useTranslation();
    const clickHandler=()=>{
        window.scrollTo(0, 0);
        props.history.push(t(`MenuContactPage`));
    }
    
    return (
        <div className='flex items-center' style={props.bg?{backgroundColor:'#F8F8F8'}:null}>
            <div className='m-auto text-center py-6'>
            {i18n.language === 'he'&& 
                <p className='md:text-xl lg:text-2xl pb-6 py-b' style={{ color: appColor }}>{t('questions')}</p>}
                <img className='w-8 md:w-16 self-center m-auto' src={icon} alt='' />
                <button onClick={clickHandler} style={{ color: '#F8F8F8' }} className={`flex shadow-lg py-2 px-8 m-auto mt-6 onhover flex-row space-x-1 items-center`}>
                    <p className='text-xl md:text-3xl font-bold mx-1' style={{ color: appColor }}>{t('ContactUs')}</p>
                    <img className={`w-4 flex self-center ${i18n.language === 'en' ? ' imgFlip' : ''}`} src={arrow} alt=''/>
                </button>
            </div>
        </div>)
}

export default withRouter(Contact);