import React from 'react';
import icon from './../../assets/icons/add_circle_outline-blue-24px.svg';
import img from './../../assets/SecureServices/park-02.jpg';
import img2 from './../../assets/SecureServices/park-03.jpg';
import Points from './../Points';
import bgImg from './../../assets/SecureServices/Bg3.jpg';
import { useTranslation } from 'react-i18next';
import i18n from './../../i18n';
import engImg1 from './../../assets/eng/airports1.jpg';
import engImg2 from './../../assets/eng/airports2.jpg';


const BizModal = (props) => {
    const { t } = useTranslation();
    const points = ["bizSecurityPoint1", "bizSecurityPoint2", "bizSecurityPoint3", "bizSecurityPoint4"]
    const points2 = ["bizSecurity1", "bizSecurity2", "bizSecurity3"]
    i18n.language === 'he'&& points.push( "MassEventsPagePoint5", "MassEventsPagePoint6", "MassEventsPagePoint7", "MassEventsPagePoint8", "MassEventsPagePoint9");
    i18n.language === 'en'&& points2.push( "MassEventsPagePoint4", "MassEventsPagePoint5", "MassEventsPagePoint6", "MassEventsPagePoint7", "MassEventsPagePoint8");

    return (
        <div id={props.id} className='modal' style={{ backgroundImage: `url('${bgImg}')` }}>
            <div className='p-6 md:p-12'>
                <p className='text modalInfo py-t mb-4' style={{ color: props.clr }}>{t("SecureServicesPageBizModalTitle")}</p>
                <div className='rowModal md:mt-12 justify-between'>
                    <img src={ i18n.language === 'he'?img2:engImg1} className='xl:w-1/2 object-cover' alt='' />
                    <div className='flex flex-col my-4'>
                        {points.map(point =>
                            <Points key={point} point={point} />
                        )}
                    </div>
                </div>

                <div className='reverseModal md:mt-12 md:py-0 justify-between'>
                    <div className='mb-4 xl:mx-auto px-6'>
                        <p className='text modalTitle mb-6' style={{ color: props.clr }}>{t("bizSecurityPoints2Title")}</p>
                        {points2.map(point =>
                            <Points key={point} point={point} />
                        )}
                    </div>
                    <img className='object-top xl:w-1/2' src={ i18n.language === 'he'?img:engImg2} alt='' />
                </div>

                <div className='m-auto pb-10 md:pb-0'>
                    <img onClick={() => props.clickClose(props.setModal, false, props.id)} className='modalClose' src={icon} alt='' />
                </div>
            </div>
        </div>
    )
}

export default BizModal;