import React, { Fragment } from 'react';
import logo from './../assets/Logo.png';
import ChangeLang from './ChangeLang';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Divider } from '@material-ui/core';
import homeIcon from './../assets/icons/home-24px.svg';
import telIcon from './../assets/icons/tel.svg';
import { appColor } from './../assets/appColor';
import i18n from './../i18n';

const Footer = (props) => {
    const { t } = useTranslation();
    const menuItemClicked = (to) => {
        window.scrollTo(0, 0);
        props.history.push(`/${t(to)}`)
    }
    const divider = <Divider orientation="horizontal" style={{ backgroundColor: appColor, width: '3px', height: '165px', marginRight: '60px', marginLeft: '60px', marginTop: '20px', marginBottom: '20px' }} />
    const menuItems = [
        { item: 'Home', link: 'MenuHomePage' },
        { item: 'About', link: 'MenuAboutPage' },
        { item: 'Services', link: 'MenuSecureServPage' },
        { item: 'MassEvents', link: 'MenuMassEventsPage' },
        i18n.language === 'he' && { item: 'Technology', link: 'MenuTechnologyPage' },
        i18n.language === 'he' && { item: 'Specializations', link: 'MenuSpecializationsPage' },
        i18n.language === 'en' && { item: 'Command&Control', link: 'MenuCommandPage' },
        i18n.language === 'he'&& { item: 'JOBS', link: 'JOBS' },
        { item: 'ContactUs', link: 'MenuContactPage' }
    ];

    const bizInfo = <div className='flex flex-col my-4 md:mt-0 '>
        <div className='flex flex-row-reverse justify-end'>
            <p className='text-xs md:text-lg mx-2 bold'>{t('address')}</p>
            <img className='w-4 md:w-5' src={homeIcon} alt='' />
        </div>
        <a href="tel:+97235374246">
            <div className='flex flex-row-reverse justify-end'>
                <p className='text-xs md:text-lg bold mx-2' style={{ direction: 'ltr' }}>+97235374246</p>
                <img className='w-4 md:w-5 justify-end' src={telIcon} alt='' />
            </div>
        </a>
    </div>

    return (
        <Fragment>
            <div className='hidden lg:inline'>
                <div className='flex flex-row items-center w-full mx-auto justify-center'>
                    <img className='w-32 mx-6' src={logo} alt='' />
                    {divider}
                    {i18n.language === 'he' ? bizInfo : <div className='mx-6 self-center'>
                        {menuItems.map((item, i) =>
                           <p key={i} onClick={() => menuItemClicked(item.link)} className='text-sm md:text-lg text-black cursor onhoverMenu bold leading-tight'>{t(item.item)}</p>
                        )}
                    </div>}
                    {divider}
                    {i18n.language === 'en' ? bizInfo : <div className='mx-6 self-center'>
                        {menuItems.map((item, i) =>
                            <p key={i} onClick={() => menuItemClicked(item.link)} className='text-sm md:text-lg text-black cursor onhoverMenu bold leading-tight'>{t(item.item)}</p>
                        )}
                    </div>}

                    {divider}
                    <ChangeLang footer />
                </div>
            </div>

            <div className='lg:hidden mt-6'>
                <img className='w-16 mb-4 flex justify-center m-auto' src={logo} alt='' />
                <div className='w-1/2 m-auto'>
                    <div className='self-center'>
                        {menuItems.map(item =>
                            <p key={Math.random()} onClick={() => menuItemClicked(item.link)} className='text-center text-sm font-bold text-black'>{t(item.item)}</p>
                        )}
                    </div>
                    <div className='flex justify-center mb-4'>
                        <ChangeLang footer />
                    </div>
                    <Divider orientation="horizontal" style={{ backgroundColor: appColor, width: '170px', height: '3px', margin: 'auto' }} />
                    <div className='flex flex-col text-center my-4 pt-2 md:mt-0'>
                        <div className='flex flex-row-reverse justify-center text-center'>
                            <p className='text-sm mx-2 font-bold'>{t('address')}</p>
                            <img className='w-4 self-start' src={homeIcon} alt='' />
                        </div>
                        <a href="tel:+97235374246">
                            <div className='flex flex-row-reverse mt-2 justify-center text-center'>
                                <p className='text-sm font-bold text-center mx-2' style={{ direction: 'ltr' }}>+97235374246</p>
                                <img className='w-4 justify-end' src={telIcon} alt='' />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className='h-12 lg:h-8 flex items-center ' style={{ backgroundColor: appColor }}>
                <p className='text-white text-xs text-center text-opacity-75 m-auto font-sans'>© 2019 SHEFA SECURITY | All rights reserved | <span className='cursor' onClick={() => window.open('https://cdigital.co.il', '_blank')}>Powered by Cdigital.co.il</span></p>
            </div>
        </Fragment>
    )
}

export default withRouter(Footer);