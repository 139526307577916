import React from 'react';
import img from './../../assets/Specializations/special-05.jpg';
import img2 from './../../assets/Specializations/special-06.jpg';
import icon from './../../assets/icons/add_circle_outline-white-24px.svg';
import doneIcon from './../../assets/icons/done-24px.svg';
import Points from './../Points';
import { useTranslation } from 'react-i18next';

const Training = (props) => {
    const { t } = useTranslation();
    const points = ["TrainingInfoPoint1", "TrainingInfoPoint2", "TrainingInfoPoint3", "TrainingInfoPoint4", "TrainingInfoPoint5", "TrainingInfoPoint6", "TrainingInfoPoint7", "TrainingInfoPoint8", "TrainingInfoPoint9", "TrainingInfoPoint10", "TrainingInfoPoint11", "TrainingInfoPoint12",]

    return (
        <div className='modal bg-black p-6 md:p-12'>
            <p className='modalTxt xl:mb-6'>{t("TrainingInfo")}</p>
            <div className='rowModal flex mt-6' style={{ justifyContent: 'space-between' }} >
                <img src={img} className='xl:w-1/2 object-cover' alt='' style={{maxHeight:700}} />
                <div className='flex flex-col'>
                    <div className='my-4 xl:my-0'>
                        <p className='text modalTitle'>{t("TrainingPointsTitle")}</p>
                        {points.map(point =>
                            <Points key={point} point={point} white img={doneIcon} />
                        )}
                    </div>
                </div>
            </div>

            <div className='reverseModal xl:pt-12 flex space-x-4'>
                <p className='modalTxt m-4 md:mx-8 xl:mt-0 '>{t("TrainingInfo2")}</p>
                <img src={img2} className='xl:w-1/2 object-cover' alt='' style={{maxHeight:400}}  />
            </div>
            <div className=''>
                <img onClick={() => props.clickClose(props.setModal, false, props.id)} className='modalClose' src={icon} alt='' />
            </div>
        </div>
    )
}

export default Training;
