import React from 'react';
import icon from './../../assets/icons/add_circle_outline-24px(1).svg';
import bgImg from './../../assets/SecureServices/Bg2.png';
import img from './../../assets/eng/drons1.jpg';
import img2 from './../../assets/eng/drons2.jpg';
import Points from '../Points';
import { useTranslation } from 'react-i18next';



const DroneModal = (props) => {
    const { t } = useTranslation();
    const engPoints2 = ['Control2Point1', 'Control2Point2', 'Control2Point3', 'Control2Point4', 'Control2Point5', 'Control2Point6', 'Control2Point7', 'Control2Point8', 'Control2Point9', 'Control2Point10'];

    return (
        <div id={props.id} className='modal p-6 md:p-12' style={{ backgroundImage: `url('${bgImg}')`, backgroundSize: 'cover' }}>

            <div className='rowModal justify-between'>
                <img src={img} className='xl:w-1/2 object-cover' alt='' />
                <div>
                    <p className='info modalTitle self-start w-5/6'>{t('ControlTitle2')}</p>
                    <p className='info self-start w-5/6 mb-4'>{t('ControlInfo2')}</p>
                </div>
            </div>

            <div className='reverseModal md:mt-12'>
                <div className='xl:mx-4 w-full'>
                    <p className='text modalTitle mt-6'>{t('Control2PointsTitle')}</p>
                    {engPoints2.map(point =>
                        <Points key={point} point={point} white />
                    )}
                </div>
                <img src={img2} className='xl:w-1/2 object-cover' alt='' />
            </div>

            <div className='m-auto'>
                <img onClick={() => props.clickClose(props.setModal, false, props.id)} className='modalClose' src={icon} alt='' />
            </div>
        </div>
    )
}

export default DroneModal;