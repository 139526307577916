import React from 'react';
import icon from './../../assets/icons/add_circle_outline-blue-24px.svg';
import img2 from './../../assets/SecureServices/groups-03.jpg';
import engImg from './../../assets/Specializations/special-05.jpg';
import engImg2 from './../../assets/Specializations/special-06.jpg';
import Points from './../Points';
import i18n from './../../i18n';
import { useTranslation } from 'react-i18next';

const GroupsModal = (props) => {
    const { t } = useTranslation();
    const points = ["GroupsSecurityModalPoint1", "GroupsSecurityModalPoint2", "GroupsSecurityModalPoint3", "GroupsSecurityModalPoint4", "GroupsSecurityModalPoint5",]
    const engPoints = ["engTrainingPoint1", "engTrainingPoint2", "engTrainingPoint3", "engTrainingPoint4",
        "engTrainingPoint5", "engTrainingPoint6", "engTrainingPoint7",
        "engTrainingPoint8", "engTrainingPoint9", "engTrainingPoint10", "engTrainingPoint11", "engTrainingPoint12", "engTrainingPoint13"];
    return (
        <div id={props.id} className='modal p-6 md:px-12' style={{ backgroundColor: 'rgba(35, 149, 172, 0.11)' }}>
            <p className='modalTitle mb-6 bold w-full lg:pt-12' style={{ color: props.clr }}>{t('GroupsSecurityModalText1')}</p>
            <div className='rowModal flex'>
            {i18n.language === 'en'&& <img src={engImg} className='xl:w-1/2 object-cover' alt='' style={{maxHeight:550}}/>}
                <div className='xl:mx-4'>
                    <p className='text modalInfo pb-2 font-normal py-2 my-4 lg:mt-0' style={{ color: props.clr }}>{t("GroupsSecurityModalPointsTitle")}</p>
                    {i18n.language === 'he' ? points.map(point =>
                        <Points key={point} point={point} />
                    ) : null}
                </div>
            </div>

            <div className='reverseModal flex md:mt-12'>
                {i18n.language === 'he' ? <p className='modalTxt mt-8 md:mt-0 mx-4' style={{ color: props.clr }}>{t('GroupsSecurityModalText2')}</p> :
                    <div className='px-6'>
                        <p className='text modalTitle ' style={{ color: props.clr }}>{t("engTrainingPointsTitle")}</p>
                        {engPoints.map(point =>
                            <Points key={point} point={point} />
                        )}</div>}
                <img src={i18n.language === 'he' ? img2 : engImg2} className='xl:w-1/2 object-cover' alt=''  />
            </div>
            <div className='mx-auto'>
                <img onClick={() => props.clickClose(props.setModal, false, props.id)} className='modalClose' src={icon} alt='' />
            </div>
        </div>
    )
}

export default GroupsModal;