import React from 'react';
import icon from './../../assets/icons/add_circle_outline-white-24px.svg';
import { useTranslation } from 'react-i18next';
import img from './../../assets/SecureServices/farm-01.jpg';
import img3 from './../../assets/SecureServices/farm-03.jpg';
import Points from './../Points';
import bgImg from './../../assets/SecureServices/Bg.jpg';
import i18n from './../../i18n';
import engImg1 from './../../assets/eng/seaports1.jpg'
import engImg2 from './../../assets/eng/seaports2.jpg'

const FarmModal = (props) => {
    const { t } = useTranslation();
    const points = ["SecurityFarmModalPoint1", "SecurityFarmModalPoint2", "SecurityFarmModalPoint3", "SecurityFarmModalPoint4", "SecurityFarmModalPoint5", "SecurityFarmModalPoint6", "SecurityFarmModalPoint7"];
    const industrialParkPoints = ["IndustrialParksPoint1", "IndustrialParksPoint2", "IndustrialParksPoint3", "IndustrialParksPoint4", "IndustrialParksPoint5", "IndustrialParksPoint6"];
    const seaPortPoints = ["SeaPortPoint1", "SeaPortPoint2", "SeaPortPoint3", "SeaPortPoint4", "SeaPortPoint5", "SeaPortPoint6"];

    return (
        <div id={props.id} className='modal p-6 md:p-12 expandable' style={{ backgroundImage: `url('${bgImg}')`, backgroundSize: 'cover' }}>
            <p className='text modalInfo  md:mb-12'>{t("FarmModalInfo")}</p>
            <div className='rowModal py-6 flex md:space-x-4'>
                <img src={i18n.language === 'he' ? img : engImg1} className='mb-8 xl:w-1/2 object-cover' alt='' />
                {i18n.language === 'he' ?
                    <div>
                        <p className='text modalTitle'>{t("SecurityFarmModalTitle1")}</p>
                        <p className='modalTxt'>{t("SecurityFarmModalText1")}</p>
                        <p className='text modalTitle mt-4'>חוות סולריות</p>
                        <p className='modalTxt '>{t("SecurityFarmModalText2")}</p>
                    </div>
                    :
                    <div className='justify-start w-full'>
                        <p className='modalTxt mb-4 uppercase font-bold'>{t("IndustrialParks")}</p>
                        {industrialParkPoints.map(point =>
                            <Points key={Math.random()} point={point} white />
                        )}
                    </div>}
            </div>
            <div className='reverseModal flex justify-between py-6'>
                {i18n.language === 'he' ?
                    <div className='mx-4'>
                        <p className='text modalTitle'>{t("SecurityFarmModalTitle2")}</p>
                        <p className='modalTxt mb-4'>{t("SecurityFarmModalText3")}</p>
                        {points.map(point =>
                            <Points key={Math.random()} point={point} white />
                        )}
                    </div>
                    :
                    <div className='mx-4'>
                        <p className='modalTxt my-4 uppercase font-bold'>{t("SeaPort")}</p>
                        {seaPortPoints.map(point => {
                            let points = <Points key={Math.random()} point={point} white />;
                            if (point === 'SeaPortPoint1') {
                                points = <div className='my-4'  key={Math.random()}><Points point={point} white subPoints={["SeaPortPoint1SubPoint1", "SeaPortPoint1SubPoint2", "SeaPortPoint1SubPoint3", "SeaPortPoint1SubPoint4",]} /></div>
                            }
                            else if (point === 'SeaPortPoint2') {
                                points = <div className='my-4'  key={Math.random()}><Points point={point} white subPoints={["SeaPortPoint2SubPoint1", "SeaPortPoint2SubPoint2", "SeaPortPoint2SubPoint3", "SeaPortPoint2SubPoint4",]} /></div>
                            }
                            return points;
                        })}
                    </div>}
                <img src={i18n.language === 'he' ? img3 : engImg2} className='object-cover xl:w-1/2' alt='' />
            </div>
            <div className='m-auto'>
                <img onClick={() => props.clickClose(props.setModal, false, props.id)} className='modalClose' src={icon} alt='' />
            </div>
        </div>
    )
}

export default FarmModal;