import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import blueArrow from './../../assets/icons/blueArrow.svg';
import i18n from './../../i18n';
import logo1 from './../../assets/Customers/new/13.png';
import logo4 from './../../assets/Customers/new/hetzlia.png';
import logo5 from './../../assets/Customers/new/israel_football.png';
import logo6 from './../../assets/Customers/new/maccabiah.png';
import logo7 from './../../assets/Customers/new/namal.png';
import logo8 from './../../assets/Customers/new/shuki.png';

const Customers = (props) => {
    const { t } = useTranslation();
    const logos = [logo8, logo6, logo1, logo4, logo5,  logo7];
    const clickHandler = () => {
        window.scrollTo(0, 0);
        props.history.push(t(`MenuCustomersPage`));
    }

    return (
        <Fragment>
            <div className='flex items-center flex-col blueLines bg-white' >
                <div className='mx-auto flex flex-col self-center justify-start mt-6 lg:mt-12 xl:mt-20' style={{ direction: i18n.language === 'en' ? 'initial' : 'rtl' }}>
                    <p className='text text-black text-4xl md:text-6xl leading-none bold uppercase'> {t('ourCustomers')} </p>
                    <p onClick={clickHandler} className='text my-2 text-black flex flex-row lg:text-2xl cursor onhoverWithMargin'>{t('showCustomers')}<img className={`w-4 mx-2 ${i18n.language === 'en' ? ' imgFlip' : ''}`} src={blueArrow} alt='' /></p>
                </div>
                <div className='flex justify-start self-center mx-2 md:w-3/5 xl:w-4/5'>
                    <div className='flex pb-4 '>
                        {logos.map(logo =>
                            <img key={Math.random()} className='h-8 md:h-16 xl:h-24 mx-1 md:mx-2' src={logo} alt='' />
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(Customers);