import React from 'react';
import Header from '../Header/Header';
import Contact from '../ContactFooter';
import { appColor } from './../../assets/appColor';
import { useTranslation } from 'react-i18next';
import ShortInfo from './../ShortInfo';
import headerImg from './../../assets/MassEvents/Header.jpg';
import img from './../../assets/MassEvents/02.jpg';
import img2 from './../../assets/MassEvents/03.jpg';
import Points from './../Points';
import ScrollAnimation from 'react-animate-on-scroll';
import i18n from './../../i18n';

const MassEvents = (props) => {
    const { t } = useTranslation();
    const points = ["MassEventsPagePoint1", "MassEventsPagePoint2", "MassEventsPagePoint3", "MassEventsPagePoint4", "MassEventsPagePoint5", "MassEventsPagePoint6", "MassEventsPagePoint7", "MassEventsPagePoint8", "MassEventsPagePoint9"];
    i18n.language === 'he' && points.push("MassEventsPagePoint10", "MassEventsPagePoint11", "MassEventsPagePoint12", "MassEventsPagePoint13", "MassEventsPagePoint14", "MassEventsPagePoint15");
    return (
        <div>
            <Header imgUrl={headerImg} pageTitle={t('MassEvents')} filter />
            <ShortInfo text={t("MassEventsPageShortInfo")} />
            <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} offset={10} delay={0} animateOnce={true} >
                <div className='rowModal'>
                    <img src={img} className='w-full xl:w-1/2 object-cover  background' alt='' />

                    {i18n.language === 'he' ?
                        <div className='w-full xl:w-1/2 background flex' style={{ backgroundColor: appColor }}><div className='w-4/5 lg:w-2/3 m-auto'>
                            <p className='info m-auto xl:text-3xl'>{t('MassEventsPageInfo')}</p>
                        </div>
                        </div> :
                        <div className='w-full xl:w-1/2 flex py-6' style={{ backgroundColor: appColor }}> <div className='m-auto'>
                            <p dangerouslySetInnerHTML={{ __html: t('MassEventsPageInfo') }} className='info m-auto lg:p-4 w-5/6 xl:w-2/3'></p>
                        </div>
                        </div>}
                </div>
            </ScrollAnimation>

            <ScrollAnimation animateIn='animate__fadeInRight' duration={0.5} offset={10} delay={0} animateOnce={true} >
                <div className='rowModal  flex justify-between'>
                    <div className={`mx-4 lg:mx-0 lg:px-10 w-11/12 xl:w-1/2 my-2 ${i18n.language === 'en' ? ' self-center' : ''}`}>
                        <p className='text modalTitle mt-0 ' style={{ color: appColor }}>{t('MassEventsPagePointsTitle')}</p>
                        {points.map(point =>
                            <Points key={point} point={point} />
                        )}
                    </div>
                    <img className='w-full xl:w-1/2 object-cover' src={img2} alt='' />
                </div>
            </ScrollAnimation>
            <Contact bg />
        </div>
    )
}

export default MassEvents;