import React from 'react';
import icon from './../../assets/icons/add_circle_outline-blue-24px.svg';
import img from './../../assets/eng/safecities1.jpg';
import img2 from './../../assets/eng/safecities2.jpg';
import Points from '../Points';
import bgImg from './../../assets/SecureServices/Bg3.jpg';
import { useTranslation } from 'react-i18next';


const SafeModal = (props) => {
    const { t } = useTranslation();
    const engPoints3 = ['Control3Point1', 'Control3Point2', 'Control3Point3', 'Control3Point4', 'Control3Point5', 'Control3Point6', 'Control3Point7', 'Control3Point8', 'Control3Point9', 'Control3Point10', 'Control3Point11', 'Control3Point12', 'Control3Point13'];

    return (
        <div id={props.id} className='modal' style={{ backgroundImage: `url('${bgImg}')` }}>
            <div className='p-6 md:p-12'>
                <div className='rowModal md:mt-12 justify-between'>
                    <img src={img} className='xl:w-1/2 object-cover' alt='' />
                    <div className='py-4'>
                        <p className='info modalTitle text-black self-start w-5/6'>{t('ControlTitle3')}</p>
                        <p className='info text-black self-start w-5/6'>{t('ControlInfo3')}</p>
                    </div>
                </div>

                <div className='reverseModal py-6 md:mt-12 md:py-0 justify-between'>
                    <div className='my-4 xl:mx-auto w-full xl:px-6'>
                        {engPoints3.map(point =>
                            <Points key={point} point={point} />
                        )}
                    </div>
                    <img className='object-top object-cover xl:w-1/2' src={img2} alt='' />
                </div>

                <div className='m-auto pb-10 md:pb-0'>
                    <img onClick={() => props.clickClose(props.setModal, false, props.id)} className='modalClose' src={icon} alt='' />
                </div>
            </div>
        </div>
    )
}

export default SafeModal;