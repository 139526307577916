import React from 'react';
import icon from './../../assets/icons/add_circle_outline-24px(1).svg';
import bgImg from './../../assets/SecureServices/Bg2.png';
import img from './../../assets/SecureServices/stat-02.jpg';
import img2 from './../../assets/SecureServices/stat-03.jpg';
import Points from './../Points';
import i18n from './../../i18n';
import engimg2 from './../../assets/SecureServices/groups-03.jpg';
import { useTranslation } from 'react-i18next';

const InvestigationServices = (props) => {
    const { t } = useTranslation();
    const points = ["StationaryServicesInfoPoint1", "StationaryServicesInfoPoint2", "StationaryServicesInfoPoint3", "StationaryServicesInfoPoint4"];
    const points2 = ["StationaryServicesPoint1", "StationaryServicesPoint2", "StationaryServicesPoint3", "StationaryServicesPoint4", "StationaryServicesPoint5", "StationaryServicesPoint6", "StationaryServicesPoint7", "StationaryServicesPoint8", "StationaryServicesPoint9", "StationaryServicesPoint10", "StationaryServicesPoint11", "StationaryServicesPoint12"];
    const engPoints = ["convoysPoint1", "convoysPoint2", "convoysPoint3", "convoysPoint4", "convoysPoint5", "convoysPoint6", "convoysPoint7", "convoysPoint8", "convoysPoint9", "convoysPoint10", "convoysPoint11"];

    return (
        <div id={props.id} className='modal p-6 md:p-12' style={{ backgroundImage: `url('${bgImg}')`, backgroundSize: 'cover' }}>
            <div className='rowModal justify-between'>
            {i18n.language === 'he' &&  <img src={img} className='w-full xl:w-1/2 object-cover' alt='' />}
                {i18n.language === 'he' ?
                    <div className='xl:w-4/5 lg:ml-8'>
                        <p className='text modalInfo'>{t('StationaryServicesPointsTitle')}</p>
                        {points.map(point =>
                            <Points key={point} point={point} white />
                        )}
                    </div>
                    : <div className=''>
                        <p className='text modalTitle'>{t('VipConvoysTitle')}</p>
                        <p className='modalTxt mb-4' dangerouslySetInnerHTML={{__html: t('VipConvoysText')}}></p>
                    </div>}
            </div>

            <div className='reverseModal md:mt-12'>
                {i18n.language === 'he' ?
                    <div className='xl:mx-4'>
                        <p className='text modalTitle md:mt-0 text-white'>{t('StationaryServicesPointsTitle2')}</p>
                        {points2.map(point =>
                            <Points key={point} point={point} white />
                        )}
                    </div> :
                    <div className='xl:mx-4'>
                        <p className='text modalTitle md:mt-0 text-white'>{t('convoisPointsTitle')}</p>
                        {engPoints.map(point =>
                            <Points key={point} point={point} white />
                        )}
                    </div>}
                <img src={i18n.language === 'he' ?img2:engimg2} className='xl:w-1/2 object-cover' alt='' />
            </div>

            <div className='m-auto'>
                <img onClick={() => props.clickClose(props.setModal, false, props.id)} className='modalClose' src={icon} alt='' />
            </div>
        </div>
    )
}

export default InvestigationServices;